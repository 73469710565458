import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Modal } from 'react-bootstrap'
import Pagination from 'react-js-pagination'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { commomObj } from '../../Utils/util'
import { addCategory, categoryList } from '../../Redux/Slices/blogsMgmt'
import { BeatLoader } from 'react-spinners'
import StatusModal from '../Modals/StatusModal'
import { useLocation, useNavigate } from 'react-router-dom'


const initialState = {
    categoryModal: false,
    name: '',
    error: '',
    statusModal: false,
    id: '',
    status: '',
    type: '',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    title: 'category'
}

const Category = () => {


    const [iState, updateState] = useState(initialState);
    const [activePage, updateActivePage] = useState(1);
    const { categoryModal, name, error, id, type, search, fromDate, toDate, timeFrame } = iState;
    const { getCategoryList, loader } = useSelector(state => state.blogsMgmt);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const heading = location?.state;


    const categoryModalHideShow = (type) => {

        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, categoryModal: true, error: '', })
        }
    }
    // search:'',fromDate:'',toDate:'',timeFrame:''
    const handleStatusShow = (item, type) => {
        if (type == "blog/delete-category") {
            updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, error: '' });
    }

    const handleRefresh = () => {
        updateState(initialState);
        dispatch(categoryList());
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '' && categoryModal == false) {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
            }
            dispatch(categoryList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        if (timeFrame) {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
            }
            dispatch(categoryList(data));
        }

    }, [timeFrame])

    const handleAddCategory = () => {
        if (!name) {
            updateState({ ...iState, error: "*Name is required" })
        }
        else {
            dispatch(addCategory({ name }))
                .then((result) => {
                    console.log({ result })
                    updateState(initialState);
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        dispatch(categoryList())
                    }

                }).catch((err) => {
                    console.log({ err })

                });
        }
    }


    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
        }
        dispatch(categoryList(data))
    };

    useEffect(() => {
        dispatch(categoryList())
            .then((result) => {
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {

            });
    }, [])

    console.log(getCategoryList?.data?.total, "getCategoryList?.data?.total")

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <a
                            className="TitleLink"
                            data-toggle="modal"
                            // data-target="#AddNewCatgory"
                            onClick={() => categoryModalHideShow("show")}
                        >
                            Add New Category
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name='search'
                                        value={search}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilterApply}>Apply</button>

                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                        <option value='' selected={timeFrame == ""}>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className='error'>{error && !categoryModal ? error : ''}</span>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Category ID</th>
                                    <th>Category Name</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getCategoryList?.data?.result?.length > 0 ?
                                    getCategoryList?.data?.result?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                <td>{item?.categoryNo}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                <td>
                                                    <div className="Actions">
                                                        <a
                                                            className="Red"
                                                            data-toggle="modal"
                                                            onClick={() => handleStatusShow(item, "blog/delete-category")}
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : !loader ?
                                        <tr>
                                            <td colSpan="10">
                                                <p className='center'>No Data found.</p>
                                            </td>
                                        </tr>
                                        : ""}
                            </tbody>
                        </table>
                        {loader ? <p className="load">
                            <BeatLoader
                                loading={loader}
                                size={10}
                                color='#fd701e'
                            />
                        </p> : ""}
                    </div>
                    <div className="pagination">
                        <ul>
                            {getCategoryList?.data?.total > 0 && (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={getCategoryList?.data?.total}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <Modal
                show={categoryModal}
                onHide={() => categoryModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            href="javascript:void(0);"
                            className="CloseModal"
                            onClick={() => categoryModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>Add New Caetgory</h3>
                        <div className="form-group">
                            <label>Category Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Category Name"
                                name='name'
                                value={name}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{error}</span>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" data-dismiss="modal" onClick={handleAddCategory}>
                                Add Category
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <StatusModal iState={iState} updateState={updateState} type={type} />
        </>

    )
}

export default Category
