import React from 'react'
import SideNav from '../SideNav/SideNav'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom';

const Dashboard = () => {

    const location = useLocation();

    const heading = location?.state;
    return (
        <div>
            <SideNav />
            <Header heading={heading}/>
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        {/* <div class="TitleBox">
              <h4 class="Title">Welcome to your dashboard</h4>
          </div> */}
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel" style={{ marginLeft: "6px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Users</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Registered Users</h2>
                                <article>
                                    <h3>1251</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Registered Astrologers</h2>
                                <article>
                                    <h3>25</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Appointments</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Appointments</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-database" />
                                </span>
                                <h2>Total Upcoming Appointments</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Completed Appointments</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-cube" />
                                </span>
                                <h2>Total Cancelled Appointments</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-users" />
                                </span>
                                <h2>Total Rescheduled Appointments</h2>
                                <article>
                                    <h3>07</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total No Show Appointments</h2>
                                <article>
                                    <h3>51</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Voice Calls (offline)</h2>
                                <article>
                                    <h3>51</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Audio/Video Calls (online)</h2>
                                <article>
                                    <h3>51</h3>
                                </article>
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Video Calls (online)</h2>
                                <article>
                                    <h3>51</h3>
                                </article>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Total Revenue</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Revenue Generated form Appointments</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-database" />
                                </span>
                                <h2>Total Amount Paid to Astrologers</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Commission Earned</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Tax</h2>
                                <article>
                                    <h3>1000</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Support Enquiry</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Support Enquiry</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Support Enquiry Pending</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Support Enquiry Resolved</h2>
                                <article>
                                    <h3>45</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard