import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';

const AppointmentMgmt = () => {

    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="CommonTabs">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#Upcoming">
                                    Upcoming
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Completed">
                                    Completed
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#RescheduledCancelled"
                                >
                                    Rescheduled/Cancelled
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#NoShow">
                                    No Show
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="Upcoming">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    href="javascript:void(0);"
                                    className="TitleLink"
                                    style={{ marginTop: "-85px" }}
                                >
                                    Download CSV
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button">Apply</button>
                                            <button className="Button Cancel">
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control">
                                                <option>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="TableList">
                                <table style={{ width: "125%" }}>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Appointment ID</th>
                                            {/* <th>Customer ID</th>  */}
                                            <th>
                                                Customer <br /> Name
                                            </th>
                                            {/* <th>Astrologer ID</th>  */}
                                            <th>
                                                Astrologer <br /> Name
                                            </th>
                                            <th>
                                                Service <br /> Name
                                            </th>
                                            <th>
                                                Type of <br /> Call
                                            </th>
                                            <th>
                                                Duration of Call <br /> (in min)
                                            </th>
                                            <th>
                                                Date &amp; <br /> Time
                                            </th>
                                            <th>
                                                Amount Paid <br /> (in Rs)
                                            </th>
                                            <th>
                                                View <br /> Details
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>c-141</td>
                                            {/* <td>c-134</td> */}
                                            <td>Mr. Rohit</td>
                                            {/* <td>c-101</td>  */}
                                            <td>Kanha</td>
                                            <td>Vastu</td>
                                            <td>
                                                Voice Call <br /> (offline)
                                            </td>
                                            <td>30</td>
                                            <td>
                                                22-02-23 <br /> 09:00 AM
                                            </td>
                                            <td>500</td>
                                            <td>
                                                <div className="Actions justify-content-start">
                                                    <Link
                                                        to="/appointment-management/upcoming-details"
                                                        className="Blue ml-0"
                                                        state={{ heading: "Upcoimg Appointment Details" }}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="NewActions justify-content-center">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="Red"
                                                        data-toggle="modal"
                                                        data-target="#CancelAppointment"
                                                    >
                                                        Cancel
                                                    </a>
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="Green"
                                                        data-toggle="modal"
                                                        data-target="#RescheduleAppointment"
                                                    >
                                                        Reschedule
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="Pagination">
                                {/* <p>Total Advertisement : 50</p> */}
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-left" />
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-right" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Completed">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    href="javascript:void(0);"
                                    className="TitleLink"
                                    style={{ marginTop: "-85px" }}
                                >
                                    Download CSV
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button">Apply</button>
                                            <button className="Button Cancel">
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control">
                                                <option>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="TableList">
                                <table style={{ width: "145%" }}>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Appointment ID</th>
                                            {/* <th>Customer ID</th>  */}
                                            <th>
                                                Customer <br /> Name
                                            </th>
                                            {/* <th>Astrologer ID</th>  */}
                                            <th>
                                                Astrologer <br /> Name
                                            </th>
                                            <th>
                                                Service <br /> Name
                                            </th>
                                            <th>Type of Call</th>
                                            <th>
                                                Duration of Call <br /> (in min)
                                            </th>
                                            <th>Date &amp; Time</th>
                                            <th>
                                                Amount Paid <br /> (in Rs)
                                            </th>
                                            {/* <th>Pay Out <br> Value</th>
                                <th>Commission <br> Deducted</th>
                                <th>Amount to <br> Astrologer</th> */}
                                            <th>
                                                View <br /> Feedback
                                            </th>
                                            <th>
                                                View <br /> Details
                                            </th>
                                            <th>
                                                View <br /> Report
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>c-141</td>
                                            {/* <td>c-134</td> */}
                                            <td>Avinash Verma</td>
                                            {/* <td>c-101</td>  */}
                                            <td>Hemendra Gupta</td>
                                            <td>Vastu</td>
                                            <td>
                                                Voice Call <br /> (offline)
                                            </td>
                                            <td>30</td>
                                            <td>
                                                22-02-23 <br /> 09:00 AM
                                            </td>
                                            <td>500</td>
                                            {/* <td>300</td> 
                                <td>50</td> 
                                <td>250</td>  */}
                                            <td>
                                                <div className="Actions justify-content-start">
                                                    <a href="javscript:void(0);" className="Blue">
                                                        <i className="fa fa-eye" />
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="Actions justify-content-start">
                                                    <Link
                                                        to="/appointment-management/completed-details"
                                                        state={{ heading: "Completed Appointment Details" }}
                                                        className="Blue ml-0"
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="NewActions justify-content-center">
                                                    <span className="Red CursorPointer">Report</span>
                                                    <span className="Pink CursorPointer">
                                                        Astrologer Notes
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="Pagination">
                                {/* <p>Total Advertisement : 50</p> */}
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-left" />
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-right" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="RescheduledCancelled">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    href="javascript:void(0);"
                                    className="TitleLink"
                                    style={{ marginTop: "-85px" }}
                                >
                                    Download CSV
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button">Apply</button>
                                            <button className="Button Cancel">
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control">
                                                <option>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="TableList">
                                <table style={{ width: "140%" }}>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Appointment ID</th>
                                            {/* <th>Customer ID</th>  */}
                                            <th>
                                                Customer <br /> Name
                                            </th>
                                            {/* <th>Astrologer ID</th>  */}
                                            <th>
                                                Astrologer <br /> Name
                                            </th>
                                            <th>
                                                Service <br /> Name
                                            </th>
                                            <th>Type of Call</th>
                                            <th>
                                                Duration of Call <br /> (in min)
                                            </th>
                                            <th>Date &amp; Time</th>
                                            <th>
                                                Amount Paid <br /> (in Rs)
                                            </th>
                                            <th>
                                                View <br /> Details
                                            </th>
                                            <th>Status</th>
                                            <th>
                                                Reason of Reschedule / <br /> Cancellation
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>A-141</td>
                                            {/* <td>C-134</td> */}
                                            <td>Avinash Verma</td>
                                            {/* <td>C-101</td>  */}
                                            <td>Hemendra Gupta</td>
                                            <td>Vastu</td>
                                            <td>
                                                Voice Call <br /> (offline)
                                            </td>
                                            <td>30</td>
                                            <td>
                                                22-02-23 <br /> 09:00 AM
                                            </td>
                                            <td>500</td>
                                            <td>
                                                <div className="Actions justify-content-start">
                                                    <Link
                                                        to="/appointment-management/reschedule-details"
                                                        className="Blue ml-0"
                                                        state={{ heading: "Rescheduled/Cancelled Appointment Details" }}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="Red">Cancelled</span>
                                            </td>
                                            <td>xyz</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="Pagination">
                                {/* <p>Total Advertisement : 50</p> */}
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-left" />
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-right" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="NoShow">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    href="javascript:void(0);"
                                    className="TitleLink"
                                    style={{ marginTop: "-85px" }}
                                >
                                    Download CSV
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button">Apply</button>
                                            <button className="Button Cancel">
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control">
                                                <option>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="TableList">
                                <table style={{ width: "135%" }}>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Appointment ID</th>
                                            {/* <th>Customer ID</th>  */}
                                            <th>
                                                Customer <br /> Name
                                            </th>
                                            {/* <th>Astrologer ID</th>  */}
                                            <th>
                                                Astrologer <br /> Name
                                            </th>
                                            <th>
                                                Service <br /> Name
                                            </th>
                                            <th>Type of Call</th>
                                            <th>
                                                Duration of Call <br /> (in min)
                                            </th>
                                            <th>Date &amp; Time</th>
                                            <th>
                                                Amount Paid <br /> (in Rs)
                                            </th>
                                            <th>
                                                No Show <br /> From
                                            </th>
                                            <th>View Details</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>A-141</td>
                                            {/* <td>C-134</td> */}
                                            <td>Avinash Verma</td>
                                            {/* <td>C-101</td>  */}
                                            <td>Hemendra Gupta</td>
                                            <td>Vastu</td>
                                            <td>
                                                Voice Call <br /> (offline)
                                            </td>
                                            <td>30</td>
                                            <td>
                                                22-02-23 <br /> 09:00 AM
                                            </td>
                                            <td>500</td>
                                            <td>Customer</td>
                                            <td>
                                                <div className="Actions justify-content-start">
                                                    <Link
                                                        to="/appointment-management/noshow-details"
                                                        className="Blue ml-0"
                                                        state={{ heading: "No Show Appointment Details" }}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    className="Orange CursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#EnterRefundAmount"
                                                >
                                                    Refund
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="Pagination">
                                {/* <p>Total Advertisement : 50</p> */}
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-left" />
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i className="fa fa-angle-double-right" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ModalBox">
                <div
                    id="CancelAppointment"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Cancel Your Appointment</h3>
                                    <div className="form-group">
                                        <label>Select Your Reason</label>
                                        <select className="form-control">
                                            <option>Select your reason</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Additional Comments</label>
                                        <textarea
                                            rows={4}
                                            className="form-control"
                                            placeholder="Write here.."
                                            defaultValue={""}
                                        />
                                    </div>
                                    <h4 className="Heading text-center">
                                        Are you sure you want to cancel <br /> your appointment ?
                                    </h4>
                                    <div className="Buttons">
                                        <button
                                            type="submit"
                                            className="Button ml-0"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="RescheduleAppointment"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Reschedule Your Appointment</h3>
                                    <div className="ModalFormArea">
                                        <div className="form-group">
                                            <label>Select Your Reason</label>
                                            <select className="form-control">
                                                <option>Select your reason</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Add Your Reason Here</label>
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Write reason here"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <h4 className="Heading text-center">
                                            Are you sure you want to reschedule <br /> your appointment ?
                                        </h4>
                                        <div className="Buttons">
                                            <button
                                                type="submit"
                                                className="Button"
                                                data-toggle="modal"
                                                data-target="#RescheduleAppointmentDate"
                                                data-dismiss="modal"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="RescheduleAppointmentDate"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Reschedule Your Appointment</h3>
                                    <div className="ModalFormArea">
                                        <figure>
                                            <img src="images/calendar.png" />
                                        </figure>
                                        <div className="Buttons">
                                            <button type="submit" className="Button" data-dismiss="modal">
                                                Reschedule
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="EnterRefundAmount"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Enter Refund Amount</h3>
                                    <form>
                                        <div className="form-group">
                                            <label>Amount Paid</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter amount"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>No Show From</label>
                                            <select className="form-control">
                                                <option>--select--</option>
                                                <option>Customer</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Refund Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Refund Amount"
                                            />
                                        </div>
                                        <div className="Buttons">
                                            <button
                                                type="submit"
                                                className="Button ml-0"
                                                data-dismiss="modal"
                                            >
                                                Initiate Refund
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="UserAdd" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Create User</h3>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Number "
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Address"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Photo of User</label>
                                        <input type="file" className="form-control" />
                                    </div>
                                    <button className="Button">Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="UserEdit" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Edit User</h3>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Number "
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Address"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Photo of User</label>
                                        <input type="file" className="form-control" />
                                    </div>
                                    <button className="Button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="UserDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this User ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="OwnerDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this Owner ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ManagerDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this Manager ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="GatekeeperDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this Gatekeeper ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="TenantDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this Tenant ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default AppointmentMgmt
