import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { statusChange, userList } from '../../Redux/Slices/userMgmt';
import { serviceList } from '../../Redux/Slices/serviceMgmt';
import { blogsList, categoryList } from '../../Redux/Slices/blogsMgmt';
import { bannerList } from '../../Redux/Slices/bannerMgmt';
import { astrologerList } from '../../Redux/Slices/astrologerMgmt';
import { getPathName } from '../../Utils/util';
import { faqList } from '../../Redux/Slices/faqMgmt';
import { toast } from 'react-toastify';

const StatusModal = ({ iState, updateState, type }) => {

    const { statusModal, id, status, title } = iState;
    const dispatch = useDispatch();
    const pathname = getPathName();

    const statusModalHideShow = () => {
        updateState({ ...iState, statusModal: false, id: '', status: '' })
    }

    const handleStatus = () => {
        const payload = {
            data: {
                id: id
            },
            type
        }
        dispatch(statusChange(payload))
            .then((result) => {
                updateState({ ...iState, statusModal: false, id: '', status: '' })
                if (type == "user/status-change" || type == "user/delete-user") {
                    dispatch(userList())
                }
                else if (type == "service/status-update" || type == "service/delete-service") {
                    dispatch(serviceList())
                }
                else if (type == "blog/status-change" || type == "blog/delete-blog") {
                    dispatch(blogsList())
                }
                else if (type == "blog/delete-category") {
                    dispatch(categoryList())
                }
                else if (type == "banner/delete-banner") {
                    dispatch(bannerList())
                }
                else if (type == "astrologer/status-update") {
                    dispatch(astrologerList({ astroStatus: "approved" }))
                }
                else if (type == "faq/delete-faq") {
                    dispatch(faqList({ type: pathname }))
                    toast.success("Faq deleted Successfully")
                }
            }).catch((err) => {

            });
    }
    return (
        <Modal
            show={statusModal}
            onHide={() => statusModalHideShow("hide")}
            className="ModalBox"
        >
            <Modal.Body>
                <a
                    className="CloseModal Delete"
                    onClick={() => statusModalHideShow("hide")}
                >
                    ×
                </a>
                <div className="LogOutModalArea Delete">
                    <h5>{status === true ? "Inactive" : status === false ? "Active" : "Delete"} Confirmation</h5>
                    <p>{`Are you sure you want to ${status === true ? "Inactive" : status === false ? "Active" : "Delete"} this ${title} ?`}</p>
                    <div className="Buttons TwoButtons">
                        <button type="button" className="Button Red" data-dismiss="modal" onClick={() => statusModalHideShow("hide")}>
                            No
                        </button>
                        <button type="button" className="Button " data-dismiss="modal" onClick={handleStatus}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default StatusModal
