import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { blogsList } from '../../Redux/Slices/blogsMgmt'
import { BeatLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'
import StatusModal from '../Modals/StatusModal'
import { toast } from 'react-toastify'
import { commomObj } from '../../Utils/util'


const initialState = {
    statusModal: false,
    id: '',
    status: '',
    type: '',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    title: 'blog'
}


const BlogsMgmt = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const heading = location?.state;
    const [iState, updateState] = useState(initialState);
    const { id, type, search, fromDate, toDate, timeFrame, error } = iState;
    const [activePage, updateActivePage] = useState(1);

    const { getBlogsList, loader } = useSelector(state => state.blogsMgmt);


    const handleStatusShow = (item, type) => {
        if (type == "blog/status-change") {
            updateState({ ...iState, statusModal: true, status: item?.isActive, id: item?._id, type })
        }
        else if (type == "blog/delete-blog") {
            updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
        }
    }




    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
        }
        dispatch(blogsList(data))
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, error: '' });
    }

    const handleRefresh = () => {
        updateState(initialState);
        dispatch(blogsList());
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
            }
            dispatch(blogsList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        if (timeFrame) {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
            }
            dispatch(blogsList(data));
        }

    }, [timeFrame])




    useEffect(() => {
        dispatch(blogsList())
            .then((result) => {
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {

            });
    }, [])

    console.log({ getBlogsList })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div>

                            <Link className="TitleLink" to="/blogs-management/category" style={{ marginRight: "5px" }} state="Blogs Category">
                                Add Category
                            </Link>
                            <Link className="TitleLink" to="/blogs-management/add-blogs" style={{ marginRight: "5px" }} state={{ heading: "Add New Blog" }}>
                                Add New Blog
                            </Link>
                            <a className="TitleLink" href="javascript:void(0);">
                                <i className="fa fa-download" /> Download Excel
                            </a>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name='search'
                                        value={search}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilterApply}>Apply</button>

                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                        <option value='' selected={timeFrame == ""}>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className='error'>{error}</span>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Blog ID</th>
                                    <th>Blog Title</th>
                                    <th>Category</th>
                                    <th>Author Name</th>
                                    <th>Posted On</th>
                                    <th>Status</th>
                                    <th>Clone Blog</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getBlogsList?.data?.result?.length > 0 ?
                                    getBlogsList?.data?.result?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                <td>{item?.blogNo}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.categories?.at(0)?.name}</td>
                                                <td>{item?.authorName}</td>
                                                <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                <td>
                                                    <div className="Actions justify-content-start">

                                                        {item?.isActive ?
                                                            <a href="javascript:void(0)" className="Resend ml-0" style={{ pointerEvents: 'none' }}>
                                                                Active
                                                            </a> :
                                                            <span className="Red">Unavailable</span>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="Actions justify-content-start">
                                                        <Link to="/blogs-management/clone-blogs"
                                                            state={{ item, heading: "Clone Blog" }} className="Resend ml-0">
                                                            Clone
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="Actions">
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                onClick={() => handleStatusShow(item, "blog/status-change")}
                                                                checked={item?.isActive}
                                                            />
                                                            <span className="slider" />
                                                        </label>
                                                        <Link className="Green" to="/blogs-management/edit-blogs"
                                                            state={{ item, heading: "Edit Blog" }}
                                                        >
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                        <Link className="Blue" to="/blogs-management/blog-details"
                                                            state={{ item, heading: "Blog Details" }}
                                                        >
                                                            <i className="fa fa-eye" />
                                                        </Link>
                                                        <a
                                                            className="Red"
                                                            data-toggle="modal"
                                                            onClick={() => handleStatusShow(item, "blog/delete-blog")}
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : !loader ?
                                        <tr>
                                            <td colSpan="10">
                                                <p className='center'>No Data found.</p>
                                            </td>
                                        </tr>
                                        : ""}
                            </tbody>
                        </table>
                        {loader ? <p className="load">
                            <BeatLoader
                                loading={loader}
                                size={10}
                                color='#fd701e'
                            />
                        </p> : ""}
                    </div>
                    <div className="pagination">
                        <ul>
                            {getBlogsList?.data?.total > 0 && (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={getBlogsList?.data?.total}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </div>



            <StatusModal iState={iState} updateState={updateState} type={type} />
        </>

    )
}

export default BlogsMgmt
