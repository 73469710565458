import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const categoryList = createAsyncThunk(
    "blogsMgmt/categoryList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`blog/category-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


//================================= Blogs list===========================//
export const blogsList = createAsyncThunk(
    "blogsMgmt/blogsList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`blog/blog-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//===============================add blogs===================================

export const addBlogs = createAsyncThunk(
    "blogsMgmt/addBlogs",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");

            if (payload?.blogId && payload?.heading == "Edit Blog") {
                const response = await axiosInstance.put(`blog/edit-blog`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
            else {
                if (payload?.heading == "Clone Blog") {
                    const response = await axiosInstance.post(`blog/clone-blog`, payload, {
                        headers: {
                            Authorization: `${token}`
                        }
                    }
                    );
                    return response.data;
                }
                else if (payload?.heading == "Add New Blog") {
                    const response = await axiosInstance.post(`blog/add-blog`, payload, {
                        headers: {
                            Authorization: `${token}`
                        }
                    }
                    );
                    return response.data;
                }

            }
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const addCategory = createAsyncThunk(
    "blogsMgmt/addCategory",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`blog/add-category`, payload, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);




const initialState = {
    getCategoryList: '',
    getBlogsList: '',
    loader: ''
}

const blogsMgmtSlice = createSlice({
    name: 'blogs',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(categoryList.pending, (state) => {
            state.loader = true;
            state.getCategoryList = {}
        })
        builder.addCase(categoryList.fulfilled, (state, action) => {
            state.loader = false;
            state.getCategoryList = action.payload;
        })
        builder.addCase(categoryList.rejected, (state, action) => {
            state.loader = false;
            state.getCategoryList = action.error.message;
        });
        builder.addCase(blogsList.pending, (state) => {
            state.loader = true;
            state.getBlogsList = {}
        })
        builder.addCase(blogsList.fulfilled, (state, action) => {
            state.loader = false;
            state.getBlogsList = action.payload;
        })
        builder.addCase(blogsList.rejected, (state, action) => {
            state.loader = false;
            state.getBlogsList = action.error.message;
        });
    }
})


export default blogsMgmtSlice.reducer;


