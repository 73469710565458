import React, { useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';



const initialState = {
    docShowModal: false,
    docType: '',
    docImage: ''

}

const NewRegisterDetail = () => {



    const [iState, updateState] = useState(initialState);
    const { docShowModal, docType, docImage } = iState;
    const location = useLocation();
    const { item, heading } = location?.state;

    const handlePdf = (pdfLink) => {
        window.open(pdfLink, '_blank');
    }

    const docShowModalHideShow = (type, docType, docImage) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, docShowModal: true, docType, docImage })
        }
    }

    console.log({ docImage, item });

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow">
                            <Link to={`/astrologer-management/${item?.astroStatus}`} state="Astrologer Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="TitleBox">
                            <h4 className="Title">Person Details</h4>
                        </div>
                        <div className="DetailsArea">
                            <div className="DetailsContent">
                                <article>
                                    <p>
                                        <label>Display Name</label>
                                        <span>{item?.fullName}</span>
                                    </p>
                                    <p>
                                        <label>Full Name</label>
                                        <span>{item?.fullName}</span>
                                    </p>
                                    <p>
                                        <label>Contact Number </label>
                                        <span>{item?.contact}</span>
                                    </p>
                                    <p>
                                        <label>Email ID</label>
                                        <span>{item?.email}</span>
                                    </p>
                                    <p>
                                        <label>Gender</label>
                                        <span>{item?.gender}</span>
                                    </p>
                                    <p>
                                        <label>DOB</label>
                                        <span>{item?.dob}</span>
                                    </p>
                                    <p>
                                        <label>Marital Status</label>
                                        <span>{item?.maritalStatus}</span>
                                    </p>
                                    <p className="d-flex align-items-start">
                                        <label>Language Preference</label>
                                        {item?.language?.length > 0 ?
                                            item?.language?.map((lan, i) => {
                                                return (
                                                    <span key={i}>{`${lan?.name} ${i !== item?.language?.length - 1 ? "," : ""}`}</span>
                                                )
                                            })

                                            : ''}
                                    </p>
                                </article>
                            </div>
                            <div className="DetailsProfile">
                                <figure>
                                    <img src={item?.image} />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="TitleBox">
                            <h4 className="Title">About Yourself</h4>
                        </div>
                        <div className="BuildingPackage">
                            <p>
                                <span>{item?.aboutYou}</span>
                            </p>
                        </div>
                        <div className="TitleBox">
                            <h4 className="Title">Other Details</h4>
                        </div>
                        <div className="BuildingPackage LabelLenght">
                            {/* <h4>Other Details</h4> */}
                            <p>
                                <label>Main Source of Income</label>
                                <span>{item?.sourceOfIncome}</span>
                            </p>
                            <p>
                                <label>Are you currently working a full time job?</label>
                                <span>{item?.fullTimeJob}</span>
                            </p>
                            <p>
                                <label>Highest qualification</label>
                                <span>{item?.highestQualification}</span>
                            </p>
                            <p>
                                <label>From Where did you learn astrology?</label>
                                <span>{item?.learnAstrology}</span>
                            </p>
                            <p>
                                <label>Instagram Profile Link</label>
                                <span>{item?.instaLink}</span>
                            </p>
                            <p>
                                <label>Facebook Profile Link</label>
                                <span>{item?.facebookLink}</span>
                            </p>
                            <p>
                                <label>Min. Earning Expectations from AstroTech (in month)</label>
                                <span>Rs. {item?.minEarnExpected}</span>
                            </p>
                            <p>
                                <label>Where did you hear about AstroTech?</label>
                                <span>{item?.hearAstrotech}</span>
                            </p>
                            <p>
                                <label>Did anybody refer you to Astrotech?</label>
                                <span>{item?.referAstrotech}</span>
                            </p>
                            <p>
                                <label>Are you working on any other online platform?</label>
                                <span>{item?.workingOnAnyOther}</span>
                            </p>
                            <p>
                                <label>Name of Platform</label>
                                <span>{item?.platformName}</span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <h4>Address Details</h4>
                                    <p>
                                        <label>Address Line 1</label>
                                        <span>{item?.address1}</span>
                                    </p>
                                    <p>
                                        <label>Address Line 2</label>
                                        <span>{item?.address2}</span>
                                    </p>
                                    <p>
                                        <label>State</label>
                                        <span>{item?.state}</span>
                                    </p>
                                    <p>
                                        <label>City</label>
                                        <span>{item?.city}</span>
                                    </p>
                                    <p>
                                        <label>Country</label>
                                        <span>{item?.country}</span>
                                    </p>
                                    <p>
                                        <label>Pincode</label>
                                        <span>{item?.pincode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <h4>Skills Details</h4>
                                    <p>
                                        <label>Experience</label>
                                        <span>{item?.experience}</span>
                                    </p>
                                    <p>
                                        <label>Skills</label>
                                        {item?.skills?.length > 0 ?
                                            item?.skills?.map((lan, i) => {
                                                return (
                                                    <span key={i}>{`${lan?.name} ${i !== item?.skills?.length - 1 ? "," : ""}`}</span>
                                                )
                                            })

                                            : ''}

                                    </p>
                                    <p>
                                        <label>Daily Contributions on AstroTech (in hrs)</label>
                                        <span>{item?.hoursContribute}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <h4>View Document</h4>
                                    <p>
                                        <label>Aadhar</label>
                                        <span>
                                            <i className="fa-solid fa-file" style={{ cursor: item?.aadharCard ? 'pointer' : 'not-allowed', color: item?.aadharCard ? '#ff9000' : '' }} onClick={item?.aadharCard ? item?.aadharCard?.endsWith(".pdf") ? () => handlePdf(item?.aadharCard) : () => docShowModalHideShow("show", "View Aadhar Card", item?.aadharCard) : null} />
                                        </span>
                                    </p>
                                    <p>
                                        <label>Pan Card</label>
                                        <span>
                                            <i className="fa-solid fa-file" style={{ cursor: item?.panCard ? 'pointer' : 'not-allowed', color: item?.panCard ? '#ff9000' : '' }} onClick={item?.panCard ? item?.panCard?.endsWith(".pdf") ? () => handlePdf(item?.panCard) : () => docShowModalHideShow("show", "View Pan Card", item?.panCard) : null} />
                                        </span>
                                    </p>
                                    <p>
                                        <label>Passport</label>
                                        <span>
                                            <i className="fa-solid fa-file" style={{ cursor: item?.passport ? 'pointer' : 'not-allowed', color: item?.passport ? '#ff9000' : '' }} onClick={item?.passport ? item?.passport?.endsWith(".pdf") ? () => handlePdf(item?.passport) : () => docShowModalHideShow("show", "View Passpost", item?.passport) : null} />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                show={docShowModal}
                onHide={() => docShowModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a

                            className="CloseModal"
                            onClick={() => docShowModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>{docType}</h3>
                        <div className="form-group">
                            <img src={docImage} />
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default NewRegisterDetail
