import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const AddSubAdmin = () => {


    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/subadmin-management" state="Sub Admin Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="CommonForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Sub-Admin Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Sub Admin Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Phone Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Role</label>
                                        <select className="form-control">
                                            <option>--Select Role--</option>
                                            <option>Manager</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <h4>Sub Admin Rights Access</h4>
                            <div className="AdminAccess">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Module Name</th>
                                            <th width="150px">Read</th>
                                            <th width="150px">Full Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Dashboard</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>User Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Astrologer Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Astrologer Management / New Registration</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Astrologer Management / Approved Astrologers</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Appointment Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Service Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Payment Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Payment Management / Transaction Details</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Payment Management / Revenue Details</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Support Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Blogs Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Sub-Admin Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Banner Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Notification Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Static Content Management</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>My Account</strong>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="CheckBox">
                                                    <input type="checkbox" />
                                                    <span className="checkmark" />
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button className="Button">Add</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AddSubAdmin
