import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const UpcomingDetail = () => {

    const location = useLocation();
    const { heading } = location?.state;


    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/appointment-management/upcoming" state="Appointment Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="DetailsArea bg-white">
                        <div className="DetailsContent">
                            <aside>
                                <p>
                                    <label>Astrologer Name</label>
                                    <span>Poonam Vashisht</span>
                                </p>
                                <p>
                                    <label>Customer Name</label>
                                    <span>Rohit Sharma</span>
                                </p>
                                <p>
                                    <label>Service Name</label>
                                    <span>Vastu</span>
                                </p>
                                <p>
                                    <label>Type of Call (in min)</label>
                                    <span>Voice Call (Offline)</span>
                                </p>
                            </aside>
                            <aside>
                                <p className="d-flex">
                                    <label>
                                        Duration of Call <br /> (in min)
                                    </label>
                                    <span>30</span>
                                </p>
                                <p>
                                    <label>Date &amp; Time</label>
                                    <span>22/03/2024, 09:00 AM</span>
                                </p>
                                <p>
                                    <label>Amount Paid (in Rs)</label>
                                    <span>500</span>
                                </p>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ModalBox">
                <div id="ReportModal" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Astrology Report</h3>
                                    <div className="AgreementDetails">
                                        <figure>
                                            <img src="images/astrology-report.png" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ValuableFeedback" className="modal fade SmallModal" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Feedback Details</h3>
                                    <div className="ModalFormArea">
                                        <h4 className="Heading">Give the ratings :</h4>
                                        <div className="form-group">
                                            <label>1. Rate your Satisfaction Level</label>
                                            <div className="RatingStar">
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>2. Rate the Astrologer</label>
                                            <div className="RatingStar">
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Tell about your Discussion Experience</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Value for Money</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Referral Preferece</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Additional Comments</label>
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Write your questions"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="Buttons">
                                            <button type="submit" className="Button" data-dismiss="modal">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="AstrologerNotes" className="modal fade SmallModal" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Previous Consultation Details</h3>
                                    <div className="ModalFormArea">
                                        <div className="BuildingPackage">
                                            <h4>View Question</h4>
                                            <p>
                                                <label>Question 1</label>
                                                <span>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                    Perspiciatis nemo accusantium modi eos aspernatur enim
                                                    quae sunt cum, commodi debitis ut animi officiis maiores
                                                    quasi, assumenda totam laudantium repellendus
                                                    reprehenderit!
                                                </span>
                                            </p>
                                            <p>
                                                <label>Question 2</label>
                                                <span>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                    Perspiciatis nemo accusantium modi eos aspernatur enim
                                                    quae sunt cum, commodi debitis ut animi officiis maiores
                                                    quasi, assumenda totam laudantium repellendus
                                                    reprehenderit!
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group">
                                            <label>2. Rate the Astrologer</label>
                                            <div className="RatingStar">
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Tell about your Discussion Experience</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Value for Money</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Referral Preferece</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Name of Person</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Enter your full name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Enter your contact number"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Additional Comments</label>
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Write your questions"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="Buttons">
                                            <button type="submit" className="Button" data-dismiss="modal">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="AstrologerNotes" className="modal fade SmallModal" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Previous Consultation Details</h3>
                                    <div className="ModalFormArea">
                                        <div className="BuildingPackage">
                                            <h4>View Question</h4>
                                            <p>
                                                <label>Question 1</label>
                                                <span>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                    Perspiciatis nemo accusantium modi eos aspernatur enim
                                                    quae sunt cum, commodi debitis ut animi officiis maiores
                                                    quasi, assumenda totam laudantium repellendus
                                                    reprehenderit!
                                                </span>
                                            </p>
                                            <p>
                                                <label>Question 2</label>
                                                <span>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                    Perspiciatis nemo accusantium modi eos aspernatur enim
                                                    quae sunt cum, commodi debitis ut animi officiis maiores
                                                    quasi, assumenda totam laudantium repellendus
                                                    reprehenderit!
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group">
                                            <label>2. Rate the Astrologer</label>
                                            <div className="RatingStar">
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-regular fa-star" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Tell about your Discussion Experience</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Value for Money</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Excellent
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Good
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Poor
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Referral Preferece</label>
                                            <ul className="MultiRadioButtons">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Yes
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        No
                                                        <input type="radio" name="Excellent" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Name of Person</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Enter your full name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Enter your contact number"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Additional Comments</label>
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Write your questions"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="Buttons">
                                            <button type="submit" className="Button" data-dismiss="modal">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="PastPackage" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>View Past Packages</h3>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Package Name</th>
                                                    <th>Flats included</th>
                                                    <th>Plan for</th>
                                                    <th>From </th>
                                                    <th>To</th>
                                                    <th>Charges</th>
                                                    <th>Payment mode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>002</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>003</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>004</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>005</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>006</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>007</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>008</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>009</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>010</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Agreement" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Agreement Details</h3>
                                    <div className="AgreementDetails">
                                        <figcaption>
                                            <p>
                                                <label>Agreement date</label>
                                                <span>22-03-2023</span>
                                            </p>
                                            <p>
                                                <label>Validity Upto </label>
                                                <span>22-08-2025</span>
                                            </p>
                                        </figcaption>
                                        <figure>
                                            <img src="images/Agreement.png" />
                                        </figure>
                                    </div>
                                    <h3>Agreement Details</h3>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Agreement Persons </th>
                                                    <th>Valid From </th>
                                                    <th>Valid Upto</th>
                                                    <th>Agreement Doc </th>
                                                    <th>Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>002</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>003</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>004</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div id="Manager" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Manager Details</h3>
                                    <div className="AgreementDetails">
                                        <figcaption>
                                            <p>
                                                <label>Manager Name</label>
                                                <span>22-03-2023</span>
                                            </p>
                                            <p>
                                                <label>Contact No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Address </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>NID Number </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Number Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Ref. NID No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Ref. NID Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Assigned Building </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Past Buildings </label>
                                                <span>
                                                    <a href="javascript:void(0);">VIEW</a>
                                                </span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </>
    )
}

export default UpcomingDetail
