import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const astrologerList = createAsyncThunk(
    "astrologerMgmt/astrologerList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`astrologer/astrologer-list?astroStatus=${payload?.astroStatus ? payload?.astroStatus : ''}&page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const scheduleInterview = createAsyncThunk(
    "astrologerMgmt/scheduleInterview",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`astrologer/schedule-interview`, payload, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const holdrejectInterview = createAsyncThunk(
    "astrologerMgmt/holdrejectInterview",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`astrologer/${payload?.endUrl}`, payload?.data, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



const initialState = {

    getAstrologerList: '',
    loader: ''
}

const astrologerMgmtSlice = createSlice({
    name: 'astrologer',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(astrologerList.pending, (state) => {
            state.loader = true;
            state.getAstrologerList = {}
        })
        builder.addCase(astrologerList.fulfilled, (state, action) => {
            state.loader = false;
            state.getAstrologerList = action.payload;
        })
        builder.addCase(astrologerList.rejected, (state, action) => {
            state.loader = false;
            state.getAstrologerList = action.error.message;
        });
    }
})


export default astrologerMgmtSlice.reducer;