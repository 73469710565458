import React, { useEffect } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Nav, Tab } from 'react-bootstrap'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userBasicDetail } from '../../Redux/Slices/userMgmt'
import { convertTo12Hour } from '../../Utils/util'

const UserDetail = () => {
    const { type } = useParams()
    const dispatch = useDispatch();
    const location = useLocation();
    let { id, heading } = location?.state;
    const { getUserBasicDetail, loader } = useSelector((state) => state.userMgmt);
    useEffect(() => {
        dispatch(userBasicDetail({ id }))
    }, [])

    console.log({ type, getUserBasicDetail })

    return (
        <div>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <Tab.Container defaultActiveKey={type}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="basicPersonal" as={Link} to='/userDetail/basicPersonal'
                                        state={{ id: id, heading: "User Management" }}
                                    > Basic Personal{" "}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="appointments" as={Link} to='/userDetail/appointments'
                                        state={{ id: id, heading: "User Management" }}
                                    > Appointments History</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </div>
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane" eventKey='basicPersonal'>
                                <div className="Small-Wrapper" style={{ width: "83%" }}>
                                    <div className="TitleBox">
                                        <h4 className="Title">Person Details</h4>
                                    </div>
                                    <div className="DetailsArea mb-4" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>Full Name</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.name}</span>
                                                </p>
                                                <p>
                                                    <label>Contact Number </label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.contact}</span>
                                                </p>
                                                <p>
                                                    <label>Email ID</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.email}</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Gender</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.gender}</span>
                                                </p>
                                                <p className="d-flex align-items-start">
                                                    <label>Language Preference</label>
                                                    {getUserBasicDetail?.data?.basicDetails?.language?.length > 0 ?
                                                        getUserBasicDetail?.data?.basicDetails?.language?.map((lan, i) => {
                                                            return (
                                                                <span key={i}>{`${lan?.name} ${i !== getUserBasicDetail?.data?.basicDetails?.language?.length - 1 ? "," : ""}`}</span>
                                                            )
                                                        })

                                                        : ''}
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                    <div className="DetailsArea mb-4" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>DOB :</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.dob}</span>
                                                </p>
                                                <p>
                                                    <label>Time of Birth</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.birthTime ? convertTo12Hour(getUserBasicDetail?.data?.basicDetails?.birthTime) : ''}</span>

                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Place of Birth</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.city}</span>
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                    <div className="DetailsArea" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>Address Line 1</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.address1}</span>
                                                </p>
                                                <p>
                                                    <label>Address Line 2</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.address2}</span>
                                                </p>
                                                <p>
                                                    <label>Country</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.country}</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>State</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.state}</span>
                                                </p>
                                                <p>
                                                    <label>City</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.city}</span>
                                                </p>
                                                <p>
                                                    <label>Pin Code </label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.pincode}</span>
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div className="TitleBox">
                                    <h4 className="Title">Family Member Details</h4>
                                </div>
                                <div className="BuildingArea">
                                    <div className="row">
                                        {getUserBasicDetail?.data?.familyMember?.length > 0 ?
                                            getUserBasicDetail?.data?.familyMember?.map((item, i) => {
                                                return (
                                                    <div className="col-lg-5">
                                                        <div className="BuildingPackage">
                                                            <p>
                                                                <label>Full Name</label>
                                                                <span>{item?.name}</span>
                                                            </p>
                                                            <p>
                                                                <label>DOB</label>
                                                                <span>{item?.dob}</span>
                                                            </p>
                                                            <p>
                                                                <label>Time of Birth</label>
                                                                <span>{convertTo12Hour(item?.birthTime)}</span>

                                                            </p>
                                                            <p>
                                                                <label>Location</label>
                                                                <span>{item?.birthPlace}</span>
                                                            </p>
                                                            <p>
                                                                <label>Relation</label>
                                                                <span>{item?.relation}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey="appointments">
                                <div className="Filter">
                                    <div className="form-group">
                                        <label>Select Appointment History</label>
                                        <select id="AppointmentSelector" className="form-control">
                                            <option value="Upcoming">Upcoming</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Cancelled">Cancel</option>
                                            <option value="NoShow">No Show</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div
                                        className="abc Upcoming"
                                        id="Upcoming"
                                        style={{ display: "block" }}
                                    >
                                        <div className="TitleBox mb-0 justify-content-end">
                                            <a
                                                className="TitleLink"
                                                href="javascript:void(0);"
                                                style={{ marginTop: "-75px" }}
                                            >
                                                <i className="fa fa-download" />
                                                Download Excel
                                            </a>
                                        </div>
                                        <div className="Small-Wrapper">
                                            <div className="FilterArea">
                                                <div className="FilterLeft">
                                                    <div className="form-group">
                                                        <label>Search</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search here.."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button className="Button">Apply</button>
                                                        <button className="Button Cancel">
                                                            <i className="fa fa-refresh" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="FilterRight">
                                                    <div className="form-group">
                                                        <label>Timeframe</label>
                                                        <select className="form-control">
                                                            <option>Select </option>
                                                            <option value="Today">Today</option>
                                                            <option value="Week">This Week</option>
                                                            <option value="Month">This Month</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="TableList">
                                            <table style={{ width: "120%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Appointment ID</th>
                                                        <th>Astrologer Name</th>
                                                        <th>Service Name</th>
                                                        <th>Type of Call</th>
                                                        <th>
                                                            Duration of <br /> Call (in min)
                                                        </th>
                                                        <th>Date &amp; Time</th>
                                                        <th>Amount Paid (in Rs.)</th>
                                                        {/* <th>Payment Method</th>
                                      <th>Appointment For</th> */}
                                                        <th>View Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>001</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24 AM
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>
                                                            <div className="Actions">
                                                                <Link className="Blue" to="/userManagement/appointment-history-details" state="User Management">
                                                                    <i className="fa fa-eye" />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="Pagination">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-left" />
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="javascript:void(0);">1</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">2</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">3</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="abc Completed" id="Completed">
                                        <div className="TitleBox mb-0 justify-content-end">
                                            <a
                                                className="TitleLink"
                                                href="javascript:void(0);"
                                                style={{ marginTop: "-75px" }}
                                            >
                                                <i className="fa fa-download" />
                                                Download Excel
                                            </a>
                                        </div>
                                        <div className="Small-Wrapper">
                                            <div className="FilterArea">
                                                <div className="FilterLeft">
                                                    <div className="form-group">
                                                        <label>Search</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search here.."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button className="Button">Apply</button>
                                                        <button className="Button Cancel">
                                                            <i className="fa fa-refresh" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="FilterRight">
                                                    <div className="form-group">
                                                        <label>Timeframe</label>
                                                        <select className="form-control">
                                                            <option>Select </option>
                                                            <option value="Today">Today</option>
                                                            <option value="Week">This Week</option>
                                                            <option value="Month">This Month</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="TableList">
                                            <table style={{ width: "140%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Appointment ID</th>
                                                        <th>Astrologer Name</th>
                                                        <th>Service Name</th>
                                                        <th>Type of Call</th>
                                                        <th>
                                                            Duration of <br /> Call (in min)
                                                        </th>
                                                        <th>Date &amp; Time</th>
                                                        <th>Amount Paid (in Rs.)</th>
                                                        {/* <th>Payment Method</th>
                                      <th>Appointment For</th> */}
                                                        <th>View Feedback</th>
                                                        <th>View Details</th>
                                                        <th>View Report</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>001</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24 AM
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a
                                                                    className="Blue ml-0"
                                                                    href="javascript:void(0);"
                                                                    data-toggle="modal"
                                                                    data-target="#ValuableFeedback"
                                                                >
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a className="Blue ml-0" href="complete-details.html">
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="NewActions justify-content-center">
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="Pink"
                                                                    data-toggle="modal"
                                                                    data-target="#ReportModal"
                                                                >
                                                                    Report
                                                                </a>
                                                                <a
                                                                    href="previous-consultant-details.html"
                                                                    className="Blue"
                                                                >
                                                                    Astrologer Notes
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="Pagination">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-left" />
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="javascript:void(0);">1</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">2</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">3</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="abc Cancelled" id="Cancelled">
                                        <div className="TitleBox mb-0 justify-content-end">
                                            <a
                                                className="TitleLink"
                                                href="javascript:void(0);"
                                                style={{ marginTop: "-75px" }}
                                            >
                                                <i className="fa fa-download" />
                                                Download Excel
                                            </a>
                                        </div>
                                        <div className="Small-Wrapper">
                                            <div className="FilterArea">
                                                <div className="FilterLeft">
                                                    <div className="form-group">
                                                        <label>Search</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search here.."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button className="Button">Apply</button>
                                                        <button className="Button Cancel">
                                                            <i className="fa fa-refresh" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="FilterRight">
                                                    <div className="form-group">
                                                        <label>Timeframe</label>
                                                        <select className="form-control">
                                                            <option>Select </option>
                                                            <option value="Today">Today</option>
                                                            <option value="Week">This Week</option>
                                                            <option value="Month">This Month</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="TableList">
                                            <table style={{ width: "140%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Appointment ID</th>
                                                        <th>Astrologer Name</th>
                                                        <th>Service Name</th>
                                                        <th>Type of Call</th>
                                                        <th>Duration of Call (in min)</th>
                                                        <th>Date &amp; Time</th>
                                                        <th>Amount Paid (in Rs.)</th>
                                                        {/* <th>Payment Method</th>
                                      <th>Appointment For</th> */}
                                                        <th>View Details</th>
                                                        <th>Reason of cancellation</th>
                                                        <th>Refund Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>001</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a className="Blue ml-0" href="cancel-details.html">
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>xyz</td>
                                                        <td>
                                                            <span className="Red">Pending</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>002</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24 AM
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a className="Blue ml-0" href="javascript:void(0);">
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>xyz</td>
                                                        <td>
                                                            <span className="Green">Completed</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="Pagination">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-left" />
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="javascript:void(0);">1</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">2</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">3</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="abc NoShow" id="NoShow">
                                        <div className="TitleBox mb-0 justify-content-end">
                                            <a
                                                className="TitleLink"
                                                href="javascript:void(0);"
                                                style={{ marginTop: "-75px" }}
                                            >
                                                <i className="fa fa-download" />
                                                Download Excel
                                            </a>
                                        </div>
                                        <div className="Small-Wrapper">
                                            <div className="FilterArea">
                                                <div className="FilterLeft">
                                                    <div className="form-group">
                                                        <label>Search</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search here.."
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button className="Button">Apply</button>
                                                        <button className="Button Cancel">
                                                            <i className="fa fa-refresh" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="FilterRight">
                                                    <div className="form-group">
                                                        <label>Timeframe</label>
                                                        <select className="form-control">
                                                            <option>Select </option>
                                                            <option value="Today">Today</option>
                                                            <option value="Week">This Week</option>
                                                            <option value="Month">This Month</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="TableList">
                                            <table style={{ width: "135%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Appointment ID</th>
                                                        <th>
                                                            Astrologer <br /> Name
                                                        </th>
                                                        <th>Service Name</th>
                                                        <th>Type of Call</th>
                                                        <th>
                                                            Duration of Call <br /> (in min)
                                                        </th>
                                                        <th>Date &amp; Time</th>
                                                        <th>
                                                            Amount Paid <br /> (in Rs.)
                                                        </th>
                                                        <th>No Show From</th>
                                                        <th>
                                                            View <br /> Details
                                                        </th>
                                                        <th>
                                                            Refund <br /> Status
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>001</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24 AM
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>Customer</td>
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a className="Blue ml-0" href="noshow-details.html">
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="Red">Pending</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>001</td>
                                                        <td>A-101</td>
                                                        <td>Kanha</td>
                                                        <td>Vastu</td>
                                                        <td>Voice Call (offline)</td>
                                                        <td>30</td>
                                                        <td>
                                                            25-03-2023 <br /> 09:10:24 AM
                                                        </td>
                                                        <td>500</td>
                                                        {/* <td>Card</td>
                                      <td>Self</td> */}
                                                        <td>Customer</td>
                                                        <td>
                                                            <div className="Actions justify-content-start">
                                                                <a className="Blue ml-0" href="javascript:void(0);">
                                                                    <i className="fa fa-eye" />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="Red">Complete</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="Pagination">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-left" />
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="javascript:void(0);">1</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">2</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">3</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <i className="fa fa-angle-double-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>

        </div>
    )
}

export default UserDetail