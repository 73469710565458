import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPathName } from '../../Utils/util';

const SideNav = () => {

    const navigate = useNavigate();
    const pathname = getPathName();

    const handleLogout = () => {
        window.localStorage.removeItem('AstroTechAdmin')
        toast.success('Sucessfully Logged Out', {
            position: 'top-right',
            autoClose: 1000
        })
        navigate('/')
    }
    return (
        <div>
            <div className="SidenavBar">
                <div className="Logo">
                    <img src={require('../../Assets/images/Logo.png')} />
                </div>
                <ul>
                    <li className={pathname == "dashboard" ? "active" : ''}>
                        <Link to="/dashboard" state='Dashboard'>
                            <span>
                                <i className="fa fa-tachometer" />
                            </span>{" "}
                            Dashboard
                        </Link>
                    </li>
                    <li className={pathname == "userManagement" || pathname == "basicPersonal" || pathname == "appointments" || pathname == "appointment-history-details" ? "active" : ""}>
                        <Link to="/userManagement" state="User Management">
                            <span>
                                <i className="fa fa-user" />
                            </span>{" "}
                            User Management
                        </Link>
                    </li>
                    <li className={pathname == "new%20registration" || pathname == "approved-astrologers"
                        || pathname == "interview"
                        || pathname == "on-hold"
                        || pathname == "rejected"
                        || pathname == "new-astrologer-details"
                        || pathname == "personal-detail"
                        || pathname == "appointment-history"
                        || pathname == "availability-schedule"
                        || pathname == "payment-details"

                        ? "dropdown active" : "dropdown"}>
                        <a
                            href="javascript:void(0)"
                            id="navbardrop"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                        >
                            <span>
                                <i className="fa fa-font" />
                            </span>{" "}
                            Astrologer Management
                        </a>
                        <ol className="dropdown-menu">
                            <li className={
                                pathname == "new%20registration"
                                    || pathname == "interview"
                                    || pathname == "on-hold"
                                    || pathname == "rejected"
                                    || pathname == "new-astrologer-details"

                                    ? "active" : ""}>
                                <Link to="/astrologer-management/new registration" state="Astrologer Management">New Registration</Link>
                            </li>
                            <li className={
                                pathname == "approved-astrologers"
                                    || pathname == "personal-detail"
                                    || pathname == "appointment-history"
                                    || pathname == "availability-schedule"
                                    || pathname == "payment-details"

                                    ? "active" : ""}>
                                <Link to="/astrologer-management/approved-astrologers" state="Approved Astrologers">Approved Astrologers</Link>
                            </li>
                        </ol>
                    </li>
                    <li className={pathname == "upcoming" || pathname == "completed" || pathname == "reschedule" || pathname == "noshow" || pathname == "upcoming-details" || pathname == "completed-details" || pathname == "reschedule-details" || pathname == "noshow-details" ? "active" : ""}>
                        <Link to="/appointment-management/upcoming" state="Appointment Management">
                            <span>
                                <i className="fa fa-font" />
                            </span>{" "}
                            Appointment Management
                        </Link>
                    </li>
                    <li className={pathname == "service-management" || pathname == "add-service" || pathname == "service-details" ? "active" : ""}>
                        <Link to="/service-management" state="Service Management">
                            <span>
                                <i className="fa fa-font" />
                            </span>{" "}
                            Service Management
                        </Link>
                    </li>
                    <li className={pathname == "transaction-details" || pathname == "revenue-details"
                        ? "dropdown active" : "dropdown"}>
                        <a
                            href="javascript:void(0)"
                            id="navbardrop"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                        >
                            <span>
                                <i className="fa-solid fa-hand-holding-dollar" />
                            </span>{" "}
                            Payment Management
                        </a>
                        <ol className="dropdown-menu">
                            <li className={pathname == "transaction-details"
                                ? "active" : ""}>
                                <Link to="/payment-management/transaction-details" state="Transaction Details">Transaction Details</Link>
                            </li>
                            <li className={pathname == "revenue-details"
                                ? "active" : ""}>
                                <Link to="/payment-management/revenue-details" state="Revenue Details">Revenue Details</Link>
                            </li>
                        </ol>
                    </li>
                    <li className={pathname == "new%20request" || pathname == "in-progress" || pathname == "resolved" || pathname == "view-details" ? "active" : ""}>
                        <Link to="/support-management/new request" state="Support Management">
                            <span>
                                <i className="fa-solid fa-phone" />
                            </span>{" "}
                            Support Management
                        </Link>
                    </li>
                    <li className={pathname == "blogs-management" || pathname == "add-blogs" || pathname == "category" ? "active" : ""}>
                        <Link to="/blogs-management" state="Blog Management">
                            <span>
                                <i className="fa fa-server" />
                            </span>{" "}
                            Blogs Management
                        </Link>
                    </li>
                    <li className={pathname == "subadmin-management" || pathname == "add-subadmin" || pathname == "sub-admin-details" ? "active" : ""}>
                        <Link to="/subadmin-management" state="Sub Admin Management">
                            <span>
                                <i className="fa fa-users" />
                            </span>{" "}
                            Sub-Admin Management
                        </Link>
                    </li>
                    <li className={pathname == "banner-management" ? "active" : ""}>
                        <Link to="/banner-management" >
                            <span><i class="fa fa-users"></i></span> Banner Management
                        </Link>
                    </li>
                    <li className={pathname == "notifications-management" || pathname == "add-category" || pathname == "notifications-thread" ? "active" : ""}>
                        <Link to="/notifications-management" state="Notification Management">
                            <span>
                                <i className="fa fa-bell" />
                            </span>{" "}
                            Notification Management
                        </Link>
                    </li>
                    <li className={pathname == "content-management" || pathname == "content-management-edit" ? "active" : ""}>
                        <Link to="/content-management">
                            <span>
                                <i className="fa fa-random" />
                            </span>{" "}
                            Static Content Management
                        </Link>
                    </li>
                    <li className={pathname == "user" || pathname == "astrologer" ? "active" : ""}>
                        <Link to="/faq-management/user" state="FAQ Management">
                            <span>
                                <i className="fa fa-random" />
                            </span>{" "}
                            FAQ Management
                        </Link>
                    </li>
                    <li className={pathname == "my-account" ? "active" : ""}>
                        <Link to="/my-account" state=" My Account">
                            <span>
                                <i className="fa fa-user" />
                            </span>{" "}
                            My Account
                        </Link>
                    </li>
                    <li>
                        <a
                            onClick={handleLogout}
                            data-toggle="modal"
                            data-target="#LogOutModal"
                        >
                            <span>
                                <i className="fa fa-sign-out" />
                            </span>{" "}
                            Logout
                        </a>
                    </li>
                </ul>
            </div>

        </div >
    )
}

export default SideNav