import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const supportList = createAsyncThunk(
    "supportMgmt/supportList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`support/enquiry-list?requestStatus=${payload?.requestStatus ? payload?.requestStatus : ''}&page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const detailList = createAsyncThunk(
    "supportMgmt/detailList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`support/view-notes?requestId=${payload?.requestId ? payload?.requestId : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const updateStatus = createAsyncThunk(
    "supportMgmt/updateStatus",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`support/status-notes`, payload, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);




const initialState = {

    getSupportList: '',
    getDetailList: '',
    loader: ''
}

const supportMgmtSlice = createSlice({
    name: 'support',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(supportList.pending, (state) => {
            state.loader = true;
            state.getSupportList = {}
        })
        builder.addCase(supportList.fulfilled, (state, action) => {
            state.loader = false;
            state.getSupportList = action.payload;
        })
        builder.addCase(supportList.rejected, (state, action) => {
            state.loader = false;
            state.getSupportList = action.error.message;
        });
        builder.addCase(detailList.pending, (state) => {
            state.loader = true;
            state.getDetailList = {}
        })
        builder.addCase(detailList.fulfilled, (state, action) => {
            state.loader = false;
            state.getDetailList = action.payload;
        })
        builder.addCase(detailList.rejected, (state, action) => {
            state.loader = false;
            state.getDetailList = action.error.message;
        });
    }
})


export default supportMgmtSlice.reducer;