import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';


const AddCategory = () => {

    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <>
                <div className="WrapperArea">
                    <div className="WrapperBox">
                        <div className="TitleBox">
                            <h4 className="Title"></h4>
                            <div className="BackArrow">
                                <Link to="/notifications-management" state="Notification Management">
                                    <i className="fa fa-long-arrow-left" /> Back
                                </Link>
                            </div>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="CommonForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Add Category</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter category"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button className="Button">Add</button>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Category Name</th>
                                        <th>Count</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <Link to="/notifications-thread" state="Notification Thread/Category">Diwali</Link>
                                        </td>
                                        <td>5</td>
                                        <td>
                                            <div className="Actions">
                                                <a
                                                    className="Red"
                                                    data-toggle="modal"
                                                    data-target="#CategoryDelete"
                                                >
                                                    <i className="fa fa-trash" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="">
                            {/* <p>Total Notification : 50</p> */}
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">
                                        <i className="fa fa-angle-double-left" />
                                    </a>
                                </li>
                                <li className="active">
                                    <a href="javascript:void(0);">1</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">2</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">3</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <i className="fa fa-angle-double-right" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="ModalBox">
                    <div
                        id="CategoryDelete"
                        className="modal fade ExtraSmallModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal Delete"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <div className="LogOutModalArea Delete">
                                        {/* <span><img src="images/logout-icon.png"></span> */}
                                        <h5>Delete Confirmation</h5>
                                        <p>
                                            Are you sure you want to delete this <br /> category ?
                                        </p>
                                        <div className="Buttons TwoButtons">
                                            <button type="button" className="Button" data-dismiss="modal">
                                                No
                                            </button>
                                            <button
                                                type="button"
                                                className="Button Red"
                                                data-dismiss="modal"
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </>

        </>
    )
}

export default AddCategory
