import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { detailList } from '../../Redux/Slices/supportMgmt'
import { formatDateWithAmPm } from '../../Utils/util'
import { Modal } from 'react-bootstrap'


const initialState = {
    docShowModal: false,
    docType: '',
    docImage: ''
}


const ViewDetails = () => {


    const [iState, updateState] = useState(initialState);
    const { docShowModal, docType, docImage } = iState;
    const location = useLocation();
    const dispatch = useDispatch();
    const { getDetailList, loader } = useSelector(state => state.supportMgmt);


    const { item, heading } = location?.state;
    const handlePdf = (pdfLink) => {
        window.open(pdfLink, '_blank');
    }

    const docShowModalHideShow = (type, docType, docImage) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, docShowModal: true, docType, docImage })
        }
    }

    useEffect(() => {
        dispatch(detailList({ requestId: item?._id }))
    }, [item])

    console.log({ getDetailList })

    console.log({ item })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="BackArrow">
                        <Link to="/support-management/new request" state="Support Management">
                            <i className="fa fa-long-arrow-left" /> Back
                        </Link>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="TitleBox">
                            <h4 className="Title">View Enquiry Details</h4>
                        </div>
                        <div className="BuildingArea">
                            <div className="BuildingPackage mb-0">
                                {/* <h4>&nbsp;</h4> */}
                                <p>
                                    <label>User Name</label>
                                    <span>{`${getDetailList?.data?.at(0)?.firstName}`}</span>
                                </p>
                                <p>
                                    <label>Contact Number</label>
                                    <span>{getDetailList?.data?.at(0)?.contact}</span>
                                </p>
                                <p>
                                    <label>Email ID</label>
                                    <span>{getDetailList?.data?.at(0)?.email}</span>
                                </p>
                                <p>
                                    <label>Message</label>
                                    <span>{getDetailList?.data?.at(0)?.message}</span>
                                </p>
                                <p>
                                    <label>View Attached Details</label>
                                    <span>
                                        <i
                                            className="fa-solid fa-file"
                                            style={{
                                                cursor: getDetailList?.data?.at(0)?.fileAttach ? 'pointer' : 'not-allowed',
                                                color: getDetailList?.data?.at(0)?.fileAttach ? '#ff9000' : ''
                                            }}
                                            onClick={getDetailList?.data?.at(0)?.fileAttach ? getDetailList?.data?.at(0)?.fileAttach?.endsWith(".pdf") ?
                                                () => handlePdf(getDetailList?.data?.at(0)?.fileAttach) :
                                                () => docShowModalHideShow("show", "View Attached Document", getDetailList?.data?.at(0)?.fileAttach) : null} />


                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>

                    {getDetailList?.data?.at(0)?.notes?.length > 0 ?
                        <>
                            <div className="TitleBox">
                                <h4 className="Title">Resolution Notes</h4>

                            </div>
                            <div className="Small-Wrapper">
                                <div className="NotificationsBox">
                                    <ul>
                                        {getDetailList?.data?.at(0)?.notes?.length > 0 ?
                                            getDetailList?.data?.at(0)?.notes?.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        <aside>
                                                            <div className="d-flex justify-content-between">
                                                                <h5>Notes :</h5>
                                                                <h6>
                                                                    Status Updated By : <span>{item?.updatedBy}</span>
                                                                </h6>
                                                            </div>
                                                            <p>
                                                                {item?.message}
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <h6>
                                                                    Status : <span>{getDetailList?.data?.at(0)?.requestStatus}</span>
                                                                </h6>
                                                                <h6>{`${item?.createdAt?.split("T")?.at(0)} , ${formatDateWithAmPm(item?.createdAt)}`}</h6>
                                                            </div>
                                                        </aside>
                                                    </li>


                                                )
                                            })
                                            : ''}
                                    </ul>
                                </div>
                            </div>
                        </>
                        : ''}
                </div>
            </div>
            <Modal
                show={docShowModal}
                onHide={() => docShowModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a

                            className="CloseModal"
                            onClick={() => docShowModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>{docType}</h3>
                        <div className="form-group">
                            <img src={docImage} />
                        </div>

                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ViewDetails
