import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const faqList = createAsyncThunk(
    "faqMgmt/faqList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`faq/faq-list?type=${payload?.type ? payload?.type : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const addFaq = createAsyncThunk(
    "FaqMgmt/addFaq",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");

            if (payload?.faqId) {
                const response = await axiosInstance.post(`faq/edit-faq`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
            else {
                const response = await axiosInstance.post(`faq/add-faq`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
        } catch (e) {
            console.log('Error', e)
        }
    }
);


const initialState = {

    getFaqList: '',
    loader: ''
}

const faqMgmtSlice = createSlice({
    name: 'faq',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(faqList.pending, (state) => {
            state.loader = true;
            state.getFaqList = {}
        })
        builder.addCase(faqList.fulfilled, (state, action) => {
            state.loader = false;
            state.getFaqList = action.payload;
        })
        builder.addCase(faqList.rejected, (state, action) => {
            state.loader = false;
            state.getFaqList = action.error.message;
        });
    }
})


export default faqMgmtSlice.reducer;