import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const NotificationThread = () => {


    const location = useLocation();
    const heading = location?.state;
    return (
        
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow mb-0">
                            <Link to="/notifications-management/add-category" state="Add Category">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel">
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="BgTransParent form-group d-flex justify-content-between align-items-center">
                        <label className="CheckBox">
                            <input type="checkbox" /> Select all
                            <span className="checkmark" />
                        </label>
                        <div>
                            <button className="MaskAsPaid">Resend All /Selected</button>
                            <button className="MaskAsPaid">Archive All/Selected</button>
                        </div>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Select</th>
                                    <th>Category</th>
                                    <th>Title</th>
                                    <th>Message</th>
                                    <th>User Type</th>
                                    <th>To User</th>
                                    <th>No. of Users</th>
                                    <th>Sent On</th>
                                    <th>Resend</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <label className="CheckBox">
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </td>
                                    <td>ABC</td>
                                    <td>ABC</td>
                                    <td>Lorem Ipsum</td>
                                    <td>All</td>
                                    <td>All</td>
                                    <td>100</td>
                                    <td>22-06-2024 09:00 AM</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a href="javascript:void(0)" className="Resend ml-0">
                                                Resend
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actions">
                                            <a
                                                className="Green"
                                                data-toggle="modal"
                                                data-target="#NotificationEdit"
                                            >
                                                <i className="fa fa-pencil" />
                                            </a>
                                            <a className="Red">
                                                <i className="fa-solid fa-box-archive" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <label className="CheckBox">
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </td>
                                    <td>ABC</td>
                                    <td>ABC</td>
                                    <td>Lorem Ipsum</td>
                                    <td>All</td>
                                    <td>All</td>
                                    <td>100</td>
                                    <td>22-06-2024 09:00 AM</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a href="javascript:void(0)" className="Resend ml-0">
                                                Resend
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actions">
                                            <a
                                                className="Green"
                                                data-toggle="modal"
                                                data-target="#NotificationEdit"
                                            >
                                                <i className="fa fa-pencil" />
                                            </a>
                                            <a className="Red">
                                                <i className="fa-solid fa-box-archive" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <label className="CheckBox">
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </td>
                                    <td>ABC</td>
                                    <td>ABC</td>
                                    <td>Lorem Ipsum</td>
                                    <td>All</td>
                                    <td>All</td>
                                    <td>100</td>
                                    <td>22-06-2024 09:00 AM</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a href="javascript:void(0)" className="Resend ml-0">
                                                Resend
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actions">
                                            <a
                                                className="Green"
                                                data-toggle="modal"
                                                data-target="#NotificationEdit"
                                            >
                                                <i className="fa fa-pencil" />
                                            </a>
                                            <a className="Red">
                                                <i className="fa-solid fa-box-archive" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <label className="CheckBox">
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </td>
                                    <td>ABC</td>
                                    <td>ABC</td>
                                    <td>Lorem Ipsum</td>
                                    <td>All</td>
                                    <td>All</td>
                                    <td>100</td>
                                    <td>22-06-2024 09:00 AM</td>
                                    <td>
                                        <div className="Actions justify-content-start">
                                            <a href="javascript:void(0)" className="Resend ml-0">
                                                Resend
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actions">
                                            <a
                                                className="Green"
                                                data-toggle="modal"
                                                data-target="#NotificationEdit"
                                            >
                                                <i className="fa fa-pencil" />
                                            </a>
                                            <a className="Red">
                                                <i className="fa-solid fa-box-archive" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Pagination">
                        {/* <p>Total Notification : 50</p> */}
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-left" />
                                </a>
                            </li>
                            <li className="active">
                                <a href="javascript:void(0);">1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">2</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">3</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-right" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ModalBox">
                <div
                    id="NotificationAdd"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Add New Notification</h3>
                                    <div className="form-group">
                                        <label>Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Title"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>User Type</label>
                                        <select className="form-control">
                                            <option>Select User Type</option>
                                            <option>All</option>
                                            <option>App User</option>
                                            <option>Tenant</option>
                                            <option>Member</option>
                                            <option>Manager</option>
                                            <option>Gatekeeper</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Notification Content</label>
                                        <textarea
                                            className="form-control"
                                            rows={4}
                                            placeholder="Enter Content"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <ul className="NotificationUl">
                                        <li>
                                            <label className="CheckBox">
                                                WhatsApp
                                                <input type="checkbox" />
                                                <span className="checkmark" />
                                            </label>
                                        </li>
                                        <label className="CheckBox">
                                            SMS
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        <label className="CheckBox">
                                            Email
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        <label className="CheckBox">
                                            Web Notification
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </ul>
                                    <div className="Buttons">
                                        <button className="Button ml-0"> Send Notification</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="NotificationEdit"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Edit Notification</h3>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>User Type</label>
                                                <select className="form-control">
                                                    <option>Select User Type</option>
                                                    <option>All</option>
                                                    <option>App User</option>
                                                    <option>Tenant</option>
                                                    <option>Member</option>
                                                    <option>Manager</option>
                                                    <option>Gatekeeper</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Notification Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Title"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Notification Content</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={4}
                                                    placeholder="Enter Content"
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Buttons">
                                        <button className="Button ml-0">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="NotificationDelete" class="modal fade LittleModal" role="dialog">
      <div class="modal-dialog">   
          <div class="modal-content"> 
              <div class="modal-body"> 
                  <div class="Decline">
                      <a href="javascript:void(0);" class="CloseModal" data-dismiss="modal">×</a>
                      <h3>Delete</h3>
                      <p>Are you sure you want to delete this Notification?</p>
                      <h4> 
                          <a href="javascript:void(0);" data-dismiss="modal">no</a>
                          <a href="javascript:void(0);" data-dismiss="modal">Yes</a>
                      </h4>
                  </div>
              </div> 
          </div>    
      </div>
  </div> */}
                <div
                    id="NotificationDelete"
                    className="modal fade ExtraSmallModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0);"
                                    className="CloseModal Delete"
                                    data-dismiss="modal"
                                >
                                    ×
                                </a>
                                <div className="LogOutModalArea Delete">
                                    {/* <span><img src="images/logout-icon.png"></span> */}
                                    <h5>Delete Confirmation</h5>
                                    <p>
                                        Are you sure you want to delete this <br /> notification ?
                                    </p>
                                    <div className="Buttons TwoButtons">
                                        <button type="button" className="Button" data-dismiss="modal">
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="Button Red"
                                            data-dismiss="modal"
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default NotificationThread
