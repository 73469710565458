import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= User list===========================//
export const serviceList = createAsyncThunk(
    "serviceMgmt/serviceList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`service/service-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//===============================add service===================================

export const addService = createAsyncThunk(
    "serviceMgmt/addService",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            if (payload?.serviceId) {
                const response = await axiosInstance.put(`service/edit-service`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
            else {
                const response = await axiosInstance.post(`service/add-service`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
        } catch (e) {
            console.log('Error', e)
        }
    }
);




const initialState = {
    getServiceList: '',
    loader: ''
}

const serviceMgmtSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(serviceList.pending, (state) => {
            state.loader = true;
            state.getServiceList = {}
        })
        builder.addCase(serviceList.fulfilled, (state, action) => {
            state.loader = false;
            state.getServiceList = action.payload;
        })
        builder.addCase(serviceList.rejected, (state, action) => {
            state.loader = false;
            state.getServiceList = action.error.message;
        });
    }
})


export default serviceMgmtSlice.reducer;


