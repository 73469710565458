import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const BlogDetails = () => {
    const location = useLocation();
    const { item, heading } = location?.state;


    console.log({ item })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow mb-0">
                            <Link to="/blogs-management" state="Blogs Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="BlogDetailsBox">
                            <h6>
                                Caterogy : <span>{item?.categories?.at(0)?.name}</span>
                            </h6>
                            <h6>
                                Author Name : <span>{item?.authorName}</span>
                            </h6>
                            <figure>
                                <img src={item?.image} />
                            </figure>
                            <aside>
                                <p>{item?.content}</p>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BlogDetails
