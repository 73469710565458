import React from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';

const RevenueDetail = () => {


    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        {/* <h4 className="Title"></h4> */}
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel">
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Completed Bookings</h2>
                                <article>
                                    <h3>1251</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Revenue Generated</h2>
                                <article>
                                    <h3>224</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Tax Deductions</h2>
                                <article>
                                    <h3>1251</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Today Amount Paid to Astrologers</h2>
                                <article>
                                    <h3>1251</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Earning (By Commission Deductions)</h2>
                                <article>
                                    <h3>1251</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default RevenueDetail
