import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const bannerList = createAsyncThunk(
    "bannerMgmt/bannerList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`banner/banner-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



//===============================add banner===================================

export const addBanner = createAsyncThunk(
    "serviceMgmt/addBanner",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            if (payload?.bannerId) {
                const response = await axiosInstance.put(`banner/edit-banner`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
            else {
                const response = await axiosInstance.post(`banner/add-banner`, payload, {
                    headers: {
                        Authorization: `${token}`
                    }
                }
                );
                return response.data;
            }
        } catch (e) {
            console.log('Error', e)
        }
    }
);





const initialState = {

    getBannerList: '',
    loader: ''
}

const bannerMgmtSlice = createSlice({
    name: 'banner',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(bannerList.pending, (state) => {
            state.loader = true;
            state.getBannerList = {}
        })
        builder.addCase(bannerList.fulfilled, (state, action) => {
            state.loader = false;
            state.getBannerList = action.payload;
        })
        builder.addCase(bannerList.rejected, (state, action) => {
            state.loader = false;
            state.getBannerList = action.error.message;
        });
    }
})


export default bannerMgmtSlice.reducer;