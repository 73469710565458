import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";
import { isLoggedIn } from "../../../Utils/util";

export const loginApiSlice = createAsyncThunk(
    "loginApi/postloginApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `send-otp`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//================================= verify otp===========================//
export const verifyOtp = createAsyncThunk(
    "verifyOtpApi/postverifyOtp",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `verify-otp`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//================================= Get Profile===========================//
export const getProfile = createAsyncThunk(
    "Login/getProfile",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`profile`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
//================================= Update Profile===========================//

export const updateProfile = createAsyncThunk(
    "Login/updateProfile",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.put(`update-profile`, payload, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



const initialState = {

    getProfileDetail: '',
    loader: ''
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getProfile.pending, (state) => {
            state.loader = true;
            state.getProfileDetail = {}
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.loader = false;
            state.getProfileDetail = action.payload;
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.loader = false;
            state.getProfileDetail = action.error.message;
        });
    }
})



export default profileSlice.reducer;