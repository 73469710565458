import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { staticContentEdit } from '../../Redux/Slices/staticMgmt';
import { commomObj } from '../../Utils/util';
import { toast } from 'react-toastify';

const StaticContentEdit = () => {


    const location = useLocation();
    const data = location?.state;

    const [content, setContent] = useState('');
    const [disable, setDisable] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleContentEdit = () => {
        setDisable(true);
        dispatch(staticContentEdit({ contentId: data?._id, description: content }))
            .then((result) => {
                if (result?.payload?.status == 200) {
                    toast.success(result?.payload?.message, commomObj);
                    navigate("/content-management")
                }
                console.log({ result })

            }).catch((err) => {
                console.log({ err })
            });

    }


    useEffect(() => {
        if (data) {
            setContent(data?.description);
        }
    }, [data])

    console.log({ location })
    return (
        <>
            <Header />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        <div className="TitleBox">
                            <h4 className="Title">{data?.title}</h4>
                            <div className="BackArrow m-0">
                                <Link to="/content-management">
                                    <i className="fa fa-long-arrow-left" /> Back
                                </Link>
                            </div>
                        </div>
                        <CKEditor
                            editor={ClassicEditor}
                            data={content || ""}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor?.getData();
                                setContent(data)
                                // console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }} />
                        <br />
                        <div className="edit">
                            <a onClick={() => handleContentEdit()} className="Button" style={{ pointerEvents: disable ? 'none' : '' }}>
                                Save Changes
                            </a>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default StaticContentEdit
