import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const AppointmentDetails = () => {

    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title">Appointment Details</h4>
                        <div className="BackArrow m-0">
                            <Link to="/userDetail/appointments" state={{ heading: "User Management" }}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="DetailsArea bg-white mb-4">
                        <div className="DetailsContent">
                            <aside>
                                <p>
                                    <label>Astrologer Name</label>
                                    <span>Poonam Vashisht</span>
                                </p>
                                <p>
                                    <label>Customer Name</label>
                                    <span>Rohit Sharma</span>
                                </p>
                                <p>
                                    <label>Service Name</label>
                                    <span>Vastu</span>
                                </p>
                                <p>
                                    <label>Type of Call (in min)</label>
                                    <span>Voice Call (Offline)</span>
                                </p>
                            </aside>
                            <aside>
                                <p className="d-flex">
                                    <label>
                                        Duration of Call <br /> (in min)
                                    </label>
                                    <span>30</span>
                                </p>
                                <p>
                                    <label>Date &amp; Time</label>
                                    <span>22/03/2024, 09:00 AM</span>
                                </p>
                                <p>
                                    <label>Amount Paid (in Rs)</label>
                                    <span>500</span>
                                </p>
                            </aside>
                        </div>
                    </div>
                    <div className="TitleBox">
                        <h4 className="Title">Members Details</h4>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <a
                                        href="javascript:void(0);"
                                        className="View"
                                        data-toggle="modal"
                                        data-target="#ViewKundli"
                                    >
                                        View Kundli
                                    </a>
                                    <h4>Member 1 Details</h4>
                                    <p>
                                        <label>Full Name</label>
                                        <span>Radha Kumari</span>
                                    </p>
                                    <p>
                                        <label>Date of Birth</label>
                                        <span>02/01/1997</span>
                                    </p>
                                    <p>
                                        <label>Time of Birth</label>
                                        <span>07:16:35 AM</span>
                                    </p>
                                    <p>
                                        <label>Location</label>
                                        <span>Block 147, Noida sector 63 A</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <a
                                        href="javascript:void(0);"
                                        className="View"
                                        data-toggle="modal"
                                        data-target="#ViewKundli"
                                    >
                                        View Kundli
                                    </a>
                                    <h4>Member 2 Details</h4>
                                    <p>
                                        <label>Full Name</label>
                                        <span>Radha Kumari</span>
                                    </p>
                                    <p>
                                        <label>Date of Birth</label>
                                        <span>02/01/1997</span>
                                    </p>
                                    <p>
                                        <label>Time of Birth</label>
                                        <span>07:16:35 AM</span>
                                    </p>
                                    <p>
                                        <label>Location</label>
                                        <span>Block 147, Noida sector 63 A</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="BuildingPackage">
                                    <h4>View Questions</h4>
                                    <p>
                                        <label>Question 1</label>
                                        <span>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                            Perspiciatis nemo accusantium modi eos aspernatur enim quae sunt
                                            cum, commodi debitis ut animi officiis maiores quasi, assumenda
                                            totam laudantium repellendus reprehenderit!
                                        </span>
                                    </p>
                                    <p>
                                        <label>Question 2</label>
                                        <span>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                            Perspiciatis nemo accusantium modi eos aspernatur enim quae sunt
                                            cum, commodi debitis ut animi officiis maiores quasi, assumenda
                                            totam laudantium repellendus reprehenderit!
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="BuildingPackage">
                                    <h4>Payment Details</h4>
                                    <p>
                                        <label>Payment Method</label>
                                        <span>Card</span>
                                    </p>
                                    <p>
                                        <label>Appointment For</label>
                                        <span>Self</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ModalBox">
                <div id="ViewKundli" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Astrology Chart</h3>
                                    <div className="AgreementDetails">
                                        <h6 className="GeneralInformation">General Information</h6>
                                        <figcaption>
                                            <aside>
                                                <p>
                                                    <label>Name</label>
                                                    <span>Rahul Bhawaliya</span>
                                                </p>
                                                <p>
                                                    <label>Date of Birth</label>
                                                    <span>Febuary 07, 1998</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Place of Birth</label>
                                                    <span>Meerut</span>
                                                </p>
                                                <p>
                                                    <label>Time of Birth</label>
                                                    <span>04:35:12 PM</span>
                                                </p>
                                            </aside>
                                        </figcaption>
                                        <figure>
                                            <img src="images/kundli.PNG" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Manager" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Manager Details</h3>
                                    <div className="AgreementDetails">
                                        <figcaption>
                                            <p>
                                                <label>Manager Name</label>
                                                <span>22-03-2023</span>
                                            </p>
                                            <p>
                                                <label>Contact No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Address </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>NID Number </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Number Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Ref. NID No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Ref. NID Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Assigned Building </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Past Buildings </label>
                                                <span>
                                                    <a href="javascript:void(0);">VIEW</a>
                                                </span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AppointmentDetails
