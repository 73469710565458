import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useLocation } from 'react-router-dom'

const TransactionDetail = () => {

    const location = useLocation();
    const heading = location?.state;

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <a className="TitleLink" href="javascript:void(0);">
                            <i className="fa fa-download" /> Download Excel
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel">
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TableList">
                        <table style={{ width: "110%" }}>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Transaction ID</th>
                                    <th>Transaction Date &amp; Time</th>
                                    <th>User/Astrologer ID</th>
                                    <th>User/Astrologer Name</th>
                                    <th>Amount (in Rs.)</th>
                                    <th>Payment Status</th>
                                    <th>Download Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>001</td>
                                    <td>T-141s</td>
                                    <td>22-02-2023, 09:00 AM</td>
                                    <td>ID-101</td>
                                    <td>Kanha</td>
                                    <td>500</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);" className="Blue">
                                            {" "}
                                            Download{" "}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>001</td>
                                    <td>T-141s</td>
                                    <td>22-02-2023, 09:00 AM</td>
                                    <td>ID-101</td>
                                    <td>Kanha</td>
                                    <td>500</td>
                                    <td>
                                        <span className="Red">Failed</span>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);" className="Blue">
                                            {" "}
                                            Download{" "}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>001</td>
                                    <td>T-141s</td>
                                    <td>22-02-2023, 09:00 AM</td>
                                    <td>ID-101</td>
                                    <td>Kanha</td>
                                    <td>500</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);" className="Blue">
                                            {" "}
                                            Download{" "}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>001</td>
                                    <td>T-141s</td>
                                    <td>22-02-2023, 09:00 AM</td>
                                    <td>ID-101</td>
                                    <td>Kanha</td>
                                    <td>500</td>
                                    <td>
                                        <span className="Green">Successful</span>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);" className="Blue">
                                            {" "}
                                            Download{" "}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className="Pagination">
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-left" />
                                </a>
                            </li>
                            <li className="active">
                                <a href="javascript:void(0);">1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">2</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">3</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-right" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )
}

export default TransactionDetail
