import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginApiSlice } from '../../Redux/Slices/Login/login'
import { useNavigate } from 'react-router-dom'
import { NavItem } from 'react-bootstrap'

const initialState = {
    phoneNumber: '',
    acceptTerms: false,
    error: '',
}
const Login = () => {
    const [iState, updateState] = useState(initialState)
    const { phoneNumber, acceptTerms, error } = iState;
    const [input, setInput] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if (phoneNumber?.length === 10) {
            setInput(false)
        }
    }, [phoneNumber])

    const handleKeyDown = (e) => {
        const { name } = e.target;
        if (e.key == 'Backspace' && name == 'phoneNumber') {
            setInput(true);
        }
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        const modiefiedValue = value > 0 ? value : phoneNumber?.length != 1 ? phoneNumber + '' : ''
        updateState({
            ...iState,
            [name]: modiefiedValue,
            error: ''
        })
        // if (name === 'acceptTerms') {
        //     updateState({
        //         ...iState,
        //         [name]: checked
        //     })
        // }
    }
    const handleLogin = () => {
        if (phoneNumber) {
            const data = {
                emailOrMobile: phoneNumber
            }
            dispatch(loginApiSlice(data)).then((res) => {
                console.log('login Response', res)
                if (res?.payload?.status === 200) {
                    navigate('/loginOtp', { state: res?.payload?.data });
                    updateState(initialState)
                }
            })
        }
        else {
            updateState({ ...iState, error: '*Please enter valid phone number' })
        }
    }
    return (
        <div>
            <div className="LoginArea">
                <div className="LoginBox">
                    <figure>
                        <img src={require('../../Assets/images/Logo.png')} />
                    </figure>
                    <form>
                        <h3>AstroTech for Admin Login</h3>
                        <p>Enter your email address or mobile number to get OTP code</p>
                        <div className="form-group">
                            <label>Mobile Number</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Mobile No."
                                name='phoneNumber'
                                value={phoneNumber}
                                onChange={input ? handleChange : null}
                                onKeyDown={handleKeyDown}
                            />
                            <span className='error'>{error}</span>

                        </div>
                        {/* <div className="form-group">
                            <div className="Checkboxs">
                                <label className="CheckBox" style={{ fontWeight: "400!important" }}>
                                    {" "}
                                    I agree to Terms &amp; Conditions
                                    <input type="checkbox" name='acceptTerms' checked={acceptTerms} onChange={handleChange} />
                                    <span className="checkmark" />
                                </label>
                            </div>
                        </div> */}
                        <a className="Button" onClick={handleLogin}>
                            Login
                        </a>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default Login