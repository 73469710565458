import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const SubAdminDetail = () => {


    const location = useLocation();
    const heading = location?.state;

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/subadmin-management" state="Sub Admin Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="DetailsArea">
                            <div className="DetailsContent">
                                <aside>
                                    <p>
                                        <label>Sub Admin ID</label>
                                        <span>+912362386321</span>
                                    </p>
                                    <p>
                                        <label>Sub Admin Name</label>
                                        <span>Mr. Ramesh</span>
                                    </p>
                                    <p>
                                        <label>Contact Number</label>
                                        <span>9810384756</span>
                                    </p>
                                    <p>
                                        <label>Email ID</label>
                                        <span>abc@gmail.com</span>
                                    </p>
                                </aside>
                                <aside>
                                    <p>
                                        <label>Registered Date</label>
                                        <span>23/05/2024</span>
                                    </p>
                                    <p>
                                        <label>Role</label>
                                        <span>Manager</span>
                                    </p>
                                    <p>
                                        <label>Status</label>
                                        <span>Active</span>
                                    </p>
                                </aside>
                            </div>
                        </div>
                        <div className="CommonForm mt-4">
                            <h4>Sub Admin Rights Access</h4>
                            <fieldset disabled="">
                                <div className="AdminAccess">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Module Name</th>
                                                <th width="150px">Read</th>
                                                <th width="150px">Full Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Dashboard</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>User Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Astrologer Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Astrologer Management / New Registration</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Astrologer Management / Approved Astrologers
                                                    </strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Appointment Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Service Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Payment Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Payment Management / Transaction Details</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Payment Management / Revenue Details</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Support Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Blogs Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Sub-Admin Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Banner Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Notification Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Static Content Management</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>My Account</strong>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="CheckBox">
                                                        <input type="checkbox" defaultChecked="" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SubAdminDetail
