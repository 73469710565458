import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const Header = ({ heading}) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        window.localStorage.removeItem('AstroTechAdmin')
        toast.success('Sucessfully Logged Out', {
            position: 'top-right',
            autoClose: 1000
        })
        navigate('/')
    }
    return (
        <div>
            <div className="Header">
                <div className="HeaderBox">
                    <div className="HeaderLeft">
                    <h5>
                    {heading}</h5></div>
                    <div className="Navigation">
                        <div className="Avater">
                       
                       
                            <a href="javascript:void(0);">
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                Admin
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        {" "}
                                        Admin <span>Administrator</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        onClick={handleLogout}
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                    >
                                        <span>
                                            <i className="fa fa-sign-out" />
                                        </span>{" "}
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Header