import React, { useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useParams } from 'react-router-dom';
import { Modal, Nav, Tab } from 'react-bootstrap';

const initialState = {
    docShowModal: false,
    docType: '',
    docImage: ''

}

const ApprovedDetail = () => {

    const [iState, updateState] = useState(initialState);
    const { docShowModal, docType, docImage } = iState;
    const location = useLocation();
    const { type } = useParams();
    const { item, heading } = location?.state;



    const handlePdf = (pdfLink) => {
        window.open(pdfLink, '_blank');
    }


    const docShowModalHideShow = (type, docType, docImage) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, docShowModal: true, docType, docImage })
        }
    }
    console.log({ item })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <Tab.Container defaultActiveKey={type}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="personal-detail" as={Link} to='/astrologer-management/approved-astrologer-details/personal-detail' state={{ heading: "Approved Astrologers" }}
                                    >   Person Details{" "}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="appointment-history" as={Link} to='/astrologer-management/approved-astrologer-details/appointment-history' state={{ heading: "Approved Astrologers" }}>  Appointment History</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="availability-schedule" as={Link} to='/astrologer-management/approved-astrologer-details/availability-schedule'
                                        state={{ heading: "Approved Astrologers" }}
                                    >  Availability Schedule</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="payment-details" as={Link} to='/astrologer-management/approved-astrologer-details/payment-details'
                                        state={{ heading: "Approved Astrologers" }}
                                    >  Payment Details</Nav.Link>
                                </Nav.Item>
                                <div className="BackArrow mb-0" style={{ marginLeft: "110px" }}>
                                    <Link to="/astrologer-management/approved-astrologers" state="Approved Astrologers">
                                        <i className="fa fa-long-arrow-left" /> Back
                                    </Link>
                                </div>
                            </Nav>
                        </div>
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane" eventKey='personal-detail'>
                                <div className="Small-Wrapper">
                                    <div className="TitleBox">
                                        <h4 className="Title">Person Details</h4>
                                    </div>
                                    <div className="DetailsArea">
                                        <div className="DetailsContent">
                                            <article>
                                                <p>
                                                    <label>Display Name</label>
                                                    <span>{item?.fullName}</span>
                                                </p>
                                                <p>
                                                    <label>Full Name</label>
                                                    <span>{item?.fullName}</span>
                                                </p>
                                                <p>
                                                    <label>Contact Number </label>
                                                    <span>{item?.contact}</span>
                                                </p>
                                                <p>
                                                    <label>Email ID</label>
                                                    <span>{item?.email}</span>
                                                </p>
                                                <p>
                                                    <label>Gender</label>
                                                    <span>{item?.gender}</span>
                                                </p>
                                                <p>
                                                    <label>DOB</label>
                                                    <span>{item?.dob}</span>
                                                </p>
                                                <p>
                                                    <label>Marital Status</label>
                                                    <span>{item?.maritalStatus}</span>
                                                </p>
                                                <p className="d-flex align-items-start">
                                                    <label>Language Preference</label>
                                                    {item?.language?.length > 0 ?
                                                        item?.language?.map((lan, i) => {
                                                            return (
                                                                <span key={i}>{`${lan?.name} ${i !== item?.language?.length - 1 ? "," : ""}`}</span>
                                                            )
                                                        })

                                                        : ''}
                                                </p>
                                            </article>
                                        </div>
                                        <div className="DetailsProfile">
                                            <figure>
                                                <img src={item?.image} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="BuildingArea">
                                    <div className="TitleBox">
                                        <h4 className="Title">About Yourself</h4>
                                    </div>
                                    <div className="BuildingPackage">
                                        <p>
                                            <span>{item?.aboutYou}</span>
                                        </p>
                                    </div>
                                    <div className="TitleBox">
                                        <h4 className="Title">Other Details</h4>
                                    </div>
                                    <div className="BuildingPackage LabelLenght">
                                        {/* <h4>Other Details</h4> */}
                                        <p>
                                            <label>Main Source of Income</label>
                                            <span>{item?.sourceOfIncome}</span>
                                        </p>
                                        <p>
                                            <label>Are you currently working a full time job?</label>
                                            <span>{item?.fullTimeJob}</span>
                                        </p>
                                        <p>
                                            <label>Highest qualification</label>
                                            <span>{item?.highestQualification}</span>
                                        </p>
                                        <p>
                                            <label>From Where did you learn astrology?</label>
                                            <span>{item?.learnAstrology}</span>
                                        </p>
                                        <p>
                                            <label>Instagram Profile Link</label>
                                            <span>{item?.instaLink}</span>
                                        </p>
                                        <p>
                                            <label>Facebook Profile Link</label>
                                            <span>{item?.facebookLink}</span>
                                        </p>
                                        <p>
                                            <label>Min. Earning Expectations from AstroTech (in month)</label>
                                            <span>Rs. {item?.minEarnExpected}</span>
                                        </p>
                                        <p>
                                            <label>Where did you hear about AstroTech?</label>
                                            <span>{item?.hearAstrotech}</span>
                                        </p>
                                        <p>
                                            <label>Did anybody refer you to Astrotech?</label>
                                            <span>{item?.referAstrotech}</span>
                                        </p>
                                        <p>
                                            <label>Are you working on any other online platform?</label>
                                            <span>{item?.workingOnAnyOther}</span>
                                        </p>
                                        <p>
                                            <label>Name of Platform</label>
                                            <span>{item?.platformName}</span>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="BuildingPackage">
                                                <h4>Address Details</h4>
                                                <p>
                                                    <label>Address Line 1</label>
                                                    <span>{item?.address1}</span>
                                                </p>
                                                <p>
                                                    <label>Address Line 2</label>
                                                    <span>{item?.address2}</span>
                                                </p>
                                                <p>
                                                    <label>State</label>
                                                    <span>{item?.state}</span>
                                                </p>
                                                <p>
                                                    <label>City</label>
                                                    <span>{item?.city}</span>
                                                </p>
                                                <p>
                                                    <label>Country</label>
                                                    <span>{item?.country}</span>
                                                </p>
                                                <p>
                                                    <label>Pincode</label>
                                                    <span>{item?.pincode}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="BuildingPackage">
                                                <h4>Skills Details</h4>
                                                <p>
                                                    <label>Experience</label>
                                                    <span>{item?.experience}</span>
                                                </p>
                                                <p>
                                                    <label>Skills</label>
                                                    {item?.skills?.length > 0 ?
                                                        item?.skills?.map((lan, i) => {
                                                            return (
                                                                <span key={i}>{`${lan?.name} ${i !== item?.skills?.length - 1 ? "," : ""}`}</span>
                                                            )
                                                        })

                                                        : ''}

                                                </p>
                                                <p>
                                                    <label>Daily Contributions on AstroTech (in hrs)</label>
                                                    <span>{item?.hoursContribute}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="BuildingPackage">
                                                <h4>View Document</h4>
                                                <p>
                                                    <label>Aadhar</label>
                                                    <span>
                                                        <i className="fa-solid fa-file" style={{ cursor: item?.aadharCard ? 'pointer' : 'not-allowed' }} onClick={item?.aadharCard ? item?.aadharCard?.endsWith(".pdf") ? () => handlePdf(item?.aadharCard) : () => docShowModalHideShow("show", "View Aadhar Card", item?.aadharCard) : null} />
                                                    </span>
                                                </p>
                                                <p>
                                                    <label>Pan Card</label>
                                                    <span>
                                                        <i className="fa-solid fa-file" style={{ cursor: item?.panCard ? 'pointer' : 'not-allowed' }} onClick={item?.panCard ? item?.panCard?.endsWith(".pdf") ? () => handlePdf(item?.panCard) : () => docShowModalHideShow("show", "View Pan Card", item?.panCard) : null} />
                                                    </span>
                                                </p>
                                                <p>
                                                    <label>Passport</label>
                                                    <span>
                                                        <i className="fa-solid fa-file" style={{ cursor: item?.passport ? 'pointer' : 'not-allowed' }} onClick={item?.passport ? item?.passport?.endsWith(".pdf") ? () => handlePdf(item?.passport) : () => docShowModalHideShow("show", "View Passpost", item?.passport) : null} />
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='appointment-history'>
                                <div className="Filter">
                                    <div className="form-group">
                                        <label>Select Appointment History</label>
                                        <select className="form-control" id="AppointmentSelector">
                                            <option value="Upcoming">Upcoming</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Noshow">No Show</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="Upcoming abc" id="Upcoming">
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table style={{ width: "110%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Appointment ID</th>
                                                    <th>Customer ID</th>
                                                    <th>
                                                        Customer <br /> Name
                                                    </th>
                                                    <th>
                                                        Service <br /> Name
                                                    </th>
                                                    <th>
                                                        Type of <br /> Call
                                                    </th>
                                                    <th>
                                                        Duration of Call <br /> (in min)
                                                    </th>
                                                    <th>Date &amp; Time</th>
                                                    <th>
                                                        Amount Paid <br /> (in Rs.)
                                                    </th>
                                                    <th>
                                                        View <br /> Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>C-101</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a
                                                                className="Blue"

                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="Completed abc" id="Completed">
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table style={{ width: "190%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Appointment ID</th>
                                                    <th>Customer ID</th>
                                                    <th>
                                                        Customer <br /> Name
                                                    </th>
                                                    <th>
                                                        Service <br /> Name
                                                    </th>
                                                    <th>
                                                        Type of <br /> Call
                                                    </th>
                                                    <th>
                                                        Duration of Call <br /> (in min)
                                                    </th>
                                                    <th>Date &amp; Time</th>
                                                    <th>
                                                        Amount Paid <br /> (in Rs.)
                                                    </th>
                                                    <th>
                                                        Payment Out <br /> Value
                                                    </th>
                                                    <th>
                                                        Commission <br /> Deducted
                                                    </th>
                                                    <th>
                                                        Amount <br /> Earned
                                                    </th>
                                                    <th>
                                                        View <br /> Feedback
                                                    </th>
                                                    <th>
                                                        View <br /> Details
                                                    </th>
                                                    <th>
                                                        View <br /> Report
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>C-141</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>300</td>
                                                    <td>50</td>
                                                    <td>250</td>
                                                    <td>
                                                        <div className="Actions justify-content-start">
                                                            <a
                                                                className="Blue ml-0"
                                                                href="javascript:void(0);"
                                                                data-toggle="modal"
                                                                data-target="#ValuableFeedback"
                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="Actions justify-content-start">
                                                            <a
                                                                className="Blue ml-0"
                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="NewActions justify-content-center">
                                                            <a
                                                                href="javascript:void(0);"
                                                                className="Pink"
                                                                data-toggle="modal"
                                                                data-target="#ReportModal"
                                                            >
                                                                Report
                                                            </a>
                                                            <a
                                                                className="Blue"
                                                            >
                                                                Astrologer Report
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="Cancelled abc" id="Cancelled">
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table style={{ width: "150%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Appointment ID</th>
                                                    <th>Customer ID</th>
                                                    <th>
                                                        Customer <br /> Name
                                                    </th>
                                                    <th>
                                                        Service <br /> Name
                                                    </th>
                                                    <th>
                                                        Type of <br /> Call
                                                    </th>
                                                    <th>
                                                        Duration of Call <br /> (in min)
                                                    </th>
                                                    <th>Date &amp; Time</th>
                                                    <th>
                                                        Amount Paid <br /> (in Rs.)
                                                    </th>
                                                    <th>
                                                        View <br /> Details
                                                    </th>
                                                    <th>Status</th>
                                                    <th>Cancelled/Rescheduled By</th>
                                                    <th>
                                                        Reason of <br /> Reschedule/cancellation
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>C-123</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>
                                                        <div className="Actions justify-content-start">
                                                            <a
                                                                className="Blue ml-0"
                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="Red">Cancelled</span>
                                                    </td>
                                                    <td>Customer</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>C-123</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>
                                                        <div className="Actions justify-content-start">
                                                            <a
                                                                className="Blue ml-0"
                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="Red">Cancelled</span>
                                                    </td>
                                                    <td>Admin</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="NoShow abc" id="Noshow">
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table style={{ width: "150%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Appointment ID</th>
                                                    <th>
                                                        Astrologer <br /> Name
                                                    </th>
                                                    <th>
                                                        Service <br /> Name
                                                    </th>
                                                    <th>
                                                        Type of <br /> Call
                                                    </th>
                                                    <th>
                                                        Duration of Call
                                                        <br /> (in min)
                                                    </th>
                                                    <th>Date &amp; Time</th>
                                                    <th>
                                                        Amount Paid
                                                        <br /> (in Rs.)
                                                    </th>
                                                    <th>
                                                        Payment
                                                        <br /> Method
                                                    </th>
                                                    <th>
                                                        Appointment
                                                        <br /> For
                                                    </th>
                                                    <th>
                                                        No Show <br /> From
                                                    </th>
                                                    <th>
                                                        View <br />
                                                        Details
                                                    </th>
                                                    {/* <th>Refund <br>Status</th>  */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>Card</td>
                                                    <td>Self</td>
                                                    <td>Customer</td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a
                                                                className="Blue ml-0"
                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                        <span class="Red">Pending</span>
                                    </td>  */}
                                                </tr>
                                                <tr>
                                                    <td>001</td>
                                                    <td>A-101</td>
                                                    <td>Kanha</td>
                                                    <td>Vastu</td>
                                                    <td>Voice Call (offline)</td>
                                                    <td>30</td>
                                                    <td>25-03-2023 09:00 AM</td>
                                                    <td>500</td>
                                                    <td>Card</td>
                                                    <td>Self</td>
                                                    <td>Customer</td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a
                                                                className="Blue ml-0"

                                                            >
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                        <span class="Red">Complete</span>
                                    </td>  */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='availability-schedule'>
                                <div className="TitleBox justify-content-end m-0">
                                    {/* <a
                                        className="TitleLink"
                                        href="javascript:void(0);"
                                        style={{ marginTop: "-85px", marginRight: 75 }}
                                    >
                                        <i className="fa fa-download" /> Download Excel
                                    </a> */}
                                </div>
                                <div className="Small-Wrapper">
                                    <div className="FilterArea">
                                        <div className="FilterLeft">
                                            <div className="form-group">
                                                <label>Search</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search here.."
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>From Date</label>
                                                <input type="date" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>To Date</label>
                                                <input type="date" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>&nbsp;</label>
                                                <button className="Button">Apply</button>
                                                <button className="Button Cancel">
                                                    <i className="fa fa-refresh" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="FilterRight">
                                            <div className="form-group">
                                                <label>Timeframe</label>
                                                <select className="form-control">
                                                    <option>Select </option>
                                                    <option value="Today">Today</option>
                                                    <option value="Week">This Week</option>
                                                    <option value="Month">This Month</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="TableList">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Date</th>
                                                <th>Day</th>
                                                <th>Availability Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>28/05/2024</td>
                                                <td>Monday</td>
                                                <td>
                                                    <strong>Morning Shot :</strong> 09:00 AM - 10:00 AM <br />
                                                    <strong>Evening Shot :</strong> 07:00 PM - 08:00 PM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>01</td>
                                                <td>28/05/2024</td>
                                                <td>Monday</td>
                                                <td>
                                                    <strong>Morning Shot :</strong> 09:00 AM - 10:00 AM <br />
                                                    <strong>Evening Shot :</strong> 07:00 PM - 08:00 PM
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Pagination">
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <i className="fa fa-angle-double-left" />
                                            </a>
                                        </li>
                                        <li className="active">
                                            <a href="javascript:void(0);">1</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">2</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">3</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <i className="fa fa-angle-double-right" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='payment-details'>
                                <div className="Filter">
                                    <div className="form-group">
                                        <label>Payment Details</label>
                                        <select className="form-control" id="PaymentDetailsSelector">
                                            <option value="TransactionDetails">Transaction Details</option>
                                            <option value="PayOutCommissionDetails">
                                                Pay Out &amp; Commission Details
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div id="TransactionDetails" className="TransactionDetails xyz">
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="DashboardOverview">
                                                <span>
                                                    <i className="fa fa-user" />
                                                </span>
                                                <h2>Total Completed Bookings</h2>
                                                <article>
                                                    <h3>500</h3>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="DashboardOverview">
                                                <a >
                                                    <span>
                                                        <i className="fa fa-user" />
                                                    </span>
                                                    <h2>Total Amount (in Rs.)</h2>
                                                    <article>
                                                        <h3>65000</h3>
                                                    </article>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="DashboardOverview">
                                                <a >
                                                    <span>
                                                        <i className="fa fa-user" />
                                                    </span>
                                                    <h2>Total Commission Deductions (in Rs.)</h2>
                                                    <article>
                                                        <h3>5000</h3>
                                                    </article>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="DashboardOverview">
                                                <a >
                                                    <span>
                                                        <i className="fa fa-user" />
                                                    </span>
                                                    <h2>Total Commission Withdrawn (in Rs.)</h2>
                                                    <article>
                                                        <h3>5000</h3>
                                                    </article>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Transaction ID</th>
                                                    <th>Transaction Date &amp; Time</th>
                                                    <th>Appointment ID</th>
                                                    <th>User ID</th>
                                                    <th>User Name</th>
                                                    <th>Amount Withdrawn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>01</td>
                                                    <td>T-141</td>
                                                    <td>22-02-23, 09:00 AM</td>
                                                    <td>A-156</td>
                                                    <td>U-156</td>
                                                    <td>Ram</td>
                                                    <td>2000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    id="PayOutCommissionDetails"
                                    className="PayOutCommissionDetails xyz"
                                >
                                    <div className="TitleBox justify-content-end m-0">
                                        <a
                                            className="TitleLink"
                                            href="javascript:void(0);"
                                            style={{ marginTop: "-80px" }}
                                        >
                                            <i className="fa fa-download" /> Download Excel
                                        </a>
                                    </div>
                                    <div className="Small-Wrapper">
                                        <div className="FilterArea">
                                            <div className="FilterLeft">
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search here.."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button className="Button">Apply</button>
                                                    <button className="Button Cancel">
                                                        <i className="fa fa-refresh" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="FilterRight">
                                                <div className="form-group">
                                                    <label>Timeframe</label>
                                                    <select className="form-control">
                                                        <option>Select </option>
                                                        <option value="Today">Today</option>
                                                        <option value="Week">This Week</option>
                                                        <option value="Month">This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Appointment ID</th>
                                                    <th>Transaction Date &amp; Time</th>
                                                    <th>Appointment ID</th>
                                                    <th>User ID</th>
                                                    <th>User Name</th>
                                                    <th>Amount Withdrawn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>01</td>
                                                    <td>T-141</td>
                                                    <td>22-02-23, 09:00 AM</td>
                                                    <td>A-156</td>
                                                    <td>U-156</td>
                                                    <td>Ram</td>
                                                    <td>2000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="Pagination">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-left" />
                                                </a>
                                            </li>
                                            <li className="active">
                                                <a href="javascript:void(0);">1</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">2</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">3</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <i className="fa fa-angle-double-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    {/* <div className="tab-content">
                        <div className="tab-pane fade show active" id="PersonDetails">

                        </div>
                        <div className="tab-pane fade" id="AppointmentHistory">
                           
                        </div>
                        <div className="tab-pane fade" id="AvailabilitySchedule">
                           
                        </div>
                        <div className="tab-pane fade" id="PaymentDetails">
                           
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="ModalBox">
                <div id="ReportModal" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Astrology Report</h3>
                                    <div className="AgreementDetails">
                                        <figure>
                                            <img src="images/astrology-report.png" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="AddNewServices" className="modal fade SmallNewModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Add Pay Out Values</h3>
                                    <div className="form-group">
                                        <label>Pay Out Value for 15 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Pay Out Value for 30 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Pay Out Value for 45 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="Buttons">
                                        <button className="Button">Save Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="AddNewServices" className="modal fade SmallNewModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Add Commission Deduction Values</h3>
                                    <div className="form-group">
                                        <label>Commission Deduction Value for 15 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Commission Deduction Value for 30 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Commission Deduction Value for 45 min</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter pay out for 15 min"
                                        />
                                    </div>
                                    <div className="Buttons">
                                        <button className="Button">Save Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="PastPackage" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>View Past Packages</h3>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Package Name</th>
                                                    <th>Flats included</th>
                                                    <th>Plan for</th>
                                                    <th>From </th>
                                                    <th>To</th>
                                                    <th>Charges</th>
                                                    <th>Payment mode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>002</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>003</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>004</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>005</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>006</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>007</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>008</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>009</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                                <tr>
                                                    <td>010</td>
                                                    <td>Gold Plan</td>
                                                    <td>300</td>
                                                    <td>Yearly</td>
                                                    <td>22-03-2023</td>
                                                    <td>12-04-2024</td>
                                                    <td>$5000</td>
                                                    <td>Online</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Agreement" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Agreement Details</h3>
                                    <div className="AgreementDetails">
                                        <figcaption>
                                            <p>
                                                <label>Agreement date</label>
                                                <span>22-03-2023</span>
                                            </p>
                                            <p>
                                                <label>Validity Upto </label>
                                                <span>22-08-2025</span>
                                            </p>
                                        </figcaption>
                                        <figure>
                                            <img src="images/Agreement.png" />
                                        </figure>
                                    </div>
                                    <h3>Agreement Details</h3>
                                    <div className="TableList">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Agreement Persons </th>
                                                    <th>Valid From </th>
                                                    <th>Valid Upto</th>
                                                    <th>Agreement Doc </th>
                                                    <th>Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>001</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>002</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>003</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>004</td>
                                                    <td>Mr. Ramesh &amp; Intercom </td>
                                                    <td>12-03-2023</td>
                                                    <td>20-04-2034</td>
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <i className="fa fa-file-image-o" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <a className="Blue">
                                                                <i className="fa fa-eye" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                data-dismiss="modal"
                                                                data-target="#AgreementDelete"
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="Manager" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Manager Details</h3>
                                    <div className="AgreementDetails">
                                        <figcaption>
                                            <p>
                                                <label>Manager Name</label>
                                                <span>22-03-2023</span>
                                            </p>
                                            <p>
                                                <label>Contact No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Address </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>NID Number </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Number Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Ref. NID No. </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Ref. NID Image</label>
                                                <span>
                                                    <img src="images/Driving.png" width="100px" />
                                                </span>
                                            </p>
                                            <p>
                                                <label>Assigned Building </label>
                                                <span>22</span>
                                            </p>
                                            <p>
                                                <label>Past Buildings </label>
                                                <span>
                                                    <a href="javascript:void(0);">VIEW</a>
                                                </span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ViewKundli" className="modal fade MediumModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Astrology Chart</h3>
                                    <div className="AgreementDetails">
                                        <h6 className="GeneralInformation">GeneralInformation</h6>
                                        <figcaption>
                                            <aside>
                                                <p>
                                                    <label>Name</label>
                                                    <span>Rahul Bhawaliya</span>
                                                </p>
                                                <p>
                                                    <label>Date of Birth</label>
                                                    <span>Febuary 07, 1998</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Place of Birth</label>
                                                    <span>Meerut</span>
                                                </p>
                                                <p>
                                                    <label>Time of Birth</label>
                                                    <span>04:00 PM</span>
                                                </p>
                                            </aside>
                                        </figcaption>
                                        <figure>
                                            <img src="images/kundli.PNG" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={docShowModal}
                onHide={() => docShowModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            className="CloseModal"
                            onClick={() => docShowModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>{docType}</h3>
                        <div className="form-group">
                            <img src={docImage} />
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default ApprovedDetail
