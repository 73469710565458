import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= User lsit===========================//
export const staticCopyright = createAsyncThunk(
    "staticMgmt/staticCopyright",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=COPYRIGHT`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticAstrologer = createAsyncThunk(
    "staticMgmt/staticAstrologer",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=ASTROLOGY`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticAbout = createAsyncThunk(
    "staticMgmt/staticAbout",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=ABOUT_US`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticTerms = createAsyncThunk(
    "staticMgmt/staticTerms",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=TERMS`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticGuildelines = createAsyncThunk(
    "staticMgmt/staticGuildelines",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=GUIDELINES`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticContact = createAsyncThunk(
    "staticMgmt/staticContact",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=CONTACT_US`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticZodiac = createAsyncThunk(
    "staticMgmt/staticZodiac",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`static/list?type=ZODIAC_SIGN`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const staticContentEdit = createAsyncThunk(
    "staticMgmt/staticContentEdit",
    async (payload) => {
        try {
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`static/edit-content`, payload, {
                headers: { Authorization: `${token}` },
            });

            return response?.data;

        } catch (err) {
            return err.response
        }
    }
)


const initialState = {
    getCopyRight: '',
    getAstrologer: '',
    getAbout: '',
    getTerms: '',
    getContact: '',
    getGuildelines: '',
    getZoidac: '',
    loader: ''
}

const staticMgmtSlice = createSlice({
    name: 'staticContent',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(staticCopyright.pending, (state) => {
            state.loader = true;
            state.getCopyRight = {}
        })
        builder.addCase(staticCopyright.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getCopyRight = action.payload;
        })
        builder.addCase(staticCopyright.rejected, (state, action) => {
            state.loader = false;
            state.getCopyRight = action.error.message;
        });
        builder.addCase(staticAstrologer.pending, (state) => {
            state.loader = true;
            state.getAstrologer = {}
        })
        builder.addCase(staticAstrologer.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getAstrologer = action.payload;
        })
        builder.addCase(staticAstrologer.rejected, (state, action) => {
            state.loader = false;
            state.getAstrologer = action.error.message;
        });
        builder.addCase(staticGuildelines.pending, (state) => {
            state.loader = true;
            state.getGuildelines = {}
        })
        builder.addCase(staticGuildelines.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getGuildelines = action.payload;
        })
        builder.addCase(staticGuildelines.rejected, (state, action) => {
            state.loader = false;
            state.getGuildelines = action.error.message;
        });
        builder.addCase(staticAbout.pending, (state) => {
            state.loader = true;
            state.getAbout = {}
        })
        builder.addCase(staticAbout.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getAbout = action.payload;
        })
        builder.addCase(staticAbout.rejected, (state, action) => {
            state.loader = false;
            state.getAbout = action.error.message;
        });
        builder.addCase(staticTerms.pending, (state) => {
            state.loader = true;
            state.getTerms = {}
        })
        builder.addCase(staticTerms.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getTerms = action.payload;
        })
        builder.addCase(staticTerms.rejected, (state, action) => {
            state.loader = false;
            state.getTerms = action.error.message;
        });
        builder.addCase(staticContact.pending, (state) => {
            state.loader = true;
            state.getContact = {}
        })
        builder.addCase(staticContact.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getContact = action.payload;
        })
        builder.addCase(staticContact.rejected, (state, action) => {
            state.loader = false;
            state.getContact = action.error.message;
        });
        builder.addCase(staticZodiac.pending, (state) => {
            state.loader = true;
            state.getZoidac = {}
        })
        builder.addCase(staticZodiac.fulfilled, (state, action) => {
            console.log('dd', action.payload)
            state.loader = false;
            state.getZoidac = action.payload;
        })
        builder.addCase(staticZodiac.rejected, (state, action) => {
            state.loader = false;
            state.getZoidac = action.error.message;
        });


    }

})


export default staticMgmtSlice.reducer;
