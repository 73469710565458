import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom'

const SubAdminMgmt = () => {

    const location = useLocation();
    const heading = location?.state;
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div>
                            <Link className="TitleLink" to="/add-subadmin" style={{ marginRight: "5px" }}
                                state="Add Sub Admin"
                            >
                                Add Sub-Admin
                            </Link>
                            <a className="TitleLink" href="javascript:void(0);">
                                Download CSV
                            </a>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search here.."
                                    />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button">Apply</button>
                                    <button className="Button Cancel">
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control">
                                        <option>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Sub-Admin ID</th>
                                    <th>Sub-Admin Name</th>
                                    <th>Number</th>
                                    <th>Email ID</th>
                                    <th>Registered Date</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>S-145</td>
                                    <td>Ram Singh</td>
                                    <td>123456789</td>
                                    <td>abc@gmail.com</td>
                                    <td>23-02-24</td>
                                    <td>Manager</td>
                                    <td>
                                        <span className="Green">Active</span>
                                    </td>
                                    <td>
                                        <div className="Actions">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider" />
                                            </label>
                                            <Link className="Green" to="/edit-subadmin" state="Edit Sub Admin">
                                                <i className="fa fa-pencil" />
                                            </Link>
                                            <Link className="Blue" to="/sub-admin-details" state="Sub Admin Details">
                                                <i className="fa fa-eye" />
                                            </Link>
                                            <a
                                                className="Red"
                                                data-toggle="modal"
                                                data-target="#DeleteSubAdmin"
                                            >
                                                <i className="fa fa-trash" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Pagination">
                        <p>Total Role : 50</p>
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-left" />
                                </a>
                            </li>
                            <li className="active">
                                <a href="javascript:void(0);">1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">2</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">3</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="fa fa-angle-double-right" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ModalBox">
                <div id="DeleteSubAdmin" className="modal SmallNewModal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this Sub Admin?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SubAdminMgmt
