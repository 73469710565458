import React, { useEffect } from "react";
import "./App.css"

import {
  Route,
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Components/Login Flow/Login";
import LoginOtp from "./Components/Login Flow/LoginOtp";
import Dashboard from "./Components/DashBoard/Dashboard";
import UserManagement from "./Components/User Management/UserManagement";
import UserDetail from "./Components/User Management/UserDetail";
import StaticContent from "./Components/StaticContent/StaticContent";
import StaticContentEdit from "./Components/StaticContent/StaticContentEdit";
import BlogsMgmt from "./Components/BlogsManagement/BlogsMgmt";
import AddBlogs from "./Components/BlogsManagement/AddBlogs";
import ServiceMgmt from "./Components/ServiceManagement/ServiceMgmt";
import AddService from "./Components/ServiceManagement/AddService";
import Category from "./Components/BlogsManagement/Category";
import BannerMgmt from "./Components/BannerManagement/BannerMgmt";
import SupportMgmt from "./Components/SupportManagement/SupportMgmt";
import ApprovedAstrologer from "./Components/AstrologerManagement/ApprovedAstrologer";
import AppointmentMgmt from "./Components/AppointmentManagement/AppointmentMgmt";
import NewRegister from "./Components/AstrologerManagement/NewRegister";
import RevenueDetail from "./Components/PaymentManagement/RevenueDetail";
import TransactionDetail from "./Components/PaymentManagement/TransactionDetail";
import SubAdminMgmt from "./Components/SubAdminManagement/SubAdminMgmt";
import NotificationMgmt from "./Components/NotificationManagement/NotificationMgmt";
import MyAccount from "./Components/Account/MyAccount";
import AddCategory from "./Components/NotificationManagement/AddCategory";
import NotificationThread from "./Components/NotificationManagement/NotificationThread";
import AddSubAdmin from "./Components/SubAdminManagement/AddSubAdmin";
import SubAdminDetail from "./Components/SubAdminManagement/SubAdminDetail";
import NewRegisterDetail from "./Components/AstrologerManagement/NewRegisterDetail";
import ServiceDetail from "./Components/ServiceManagement/ServiceDetail";
import ViewDetails from "./Components/SupportManagement/ViewDetails";
import ApprovedDetail from "./Components/AstrologerManagement/ApprovedDetail";
import FaqMgmt from "./Components/FaqManagement/FaqMgmt";
import BlogDetails from "./Components/BlogsManagement/BlogDetails";
import UpcomingDetail from "./Components/AppointmentManagement/UpcomingDetail";
import RescheduleDetail from "./Components/AppointmentManagement/RescheduleDetail";
import CompletedDetail from "./Components/AppointmentManagement/CompletedDetail";
import NoshowDetail from "./Components/AppointmentManagement/NoshowDetail";
import AppointmentDetails from "./Components/User Management/AppointmentDetails";



function PrivateRoute({ component: Component, ...rest }) {
  const isLogged = localStorage.getItem("");

  return isLogged ? <Component {...rest} /> : <Navigate to="/" />;
}

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/loginOtp" element={<LoginOtp />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/userManagement" element={<UserManagement />} />
          <Route path="/userManagement/appointment-history-details" element={<AppointmentDetails />} />

          <Route path="/userDetail/:type" element={<UserDetail />} />

          <Route path="/content-management" element={<StaticContent />} />
          <Route path="/content-management-edit" element={<StaticContentEdit />} />
          <Route path="/faq-management/:pathType" element={<FaqMgmt />} />



          <Route path="/service-management" element={<ServiceMgmt />} />
          <Route path="/service-management/add-service" element={<AddService />} />
          <Route path="/service-management/edit-service" element={<AddService />} />
          <Route path="/service-management/service-details" element={<ServiceDetail />} />
          <Route path="/blogs-management" element={<BlogsMgmt />} />
          <Route path="/blogs-management/add-blogs" element={<AddBlogs />} />
          <Route path="/blogs-management/edit-blogs" element={<AddBlogs />} />
          <Route path="/blogs-management/blog-details" element={<BlogDetails />} />
          <Route path="/blogs-management/clone-blogs" element={<AddBlogs />} />


          <Route path="/blogs-management/category" element={<Category />} />

          <Route path="/banner-management" element={<BannerMgmt />} />

          <Route path="/support-management/:type" element={<SupportMgmt />} />
          <Route path="/support-management/view-details" element={<ViewDetails />} />

          <Route path="/astrologer-management/:type" element={<NewRegister />} />
          <Route path="/new-astrologer-details" element={<NewRegisterDetail />} />
          <Route path="/astrologer-management/approved-astrologers" element={<ApprovedAstrologer />} />
          <Route path="/astrologer-management/approved-astrologer-details/:type" element={<ApprovedDetail />} />



          <Route path="/appointment-management/:type" element={<AppointmentMgmt />} />
          <Route path="/appointment-management/upcoming-details" element={<UpcomingDetail />} />
          <Route path="/appointment-management/reschedule-details" element={<RescheduleDetail />} />
          <Route path="/appointment-management/completed-details" element={<CompletedDetail />} />
          <Route path="/appointment-management/noshow-details" element={<NoshowDetail />} />




          <Route path="/payment-management/transaction-details" element={<TransactionDetail />} />
          <Route path="/payment-management/revenue-details" element={<RevenueDetail />} />

          <Route path="/subadmin-management" element={<SubAdminMgmt />} />
          <Route path="/add-subadmin" element={<AddSubAdmin />} />
          <Route path="/edit-subadmin" element={<AddSubAdmin />} />
          <Route path="/sub-admin-details" element={<SubAdminDetail />} />

          <Route path="/notifications-management" element={<NotificationMgmt />} />
          <Route path="/notifications-management/add-category" element={<AddCategory />} />
          <Route path="/notifications-thread" element={<NotificationThread />} />

          <Route path="/my-account" element={<MyAccount />} />








        </Routes>
      </HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;


