import React, { useEffect, useState } from 'react'
import SideNav from '../SideNav/SideNav'
import Header from '../Header/Header'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { commomObj, getPathName } from '../../Utils/util';
import { useDispatch, useSelector } from 'react-redux';
import { astrologerList, holdrejectInterview, scheduleInterview } from '../../Redux/Slices/astrologerMgmt';
import { BeatLoader } from 'react-spinners';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';


const initialState = {
    astroStatus: "",
    scheduleModal: false,
    holdApproveRejectModal: '',
    reason: '',
    id: '',
    date: '',
    time: '',
    link: '',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    errors: "",
    endUrl: '',
}

const NewRegister = () => {

    const [iState, updateState] = useState(initialState);
    const { astroStatus, scheduleModal, holdApproveRejectModal, id, date, time, link, reason, search, fromDate, toDate, timeFrame, error, errors, endUrl } = iState;


    const [activePage, updateActivePage] = useState(1);
    const { getAstrologerList, loader } = useSelector(state => state.astrologerMgmt);
    const pathname = getPathName();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { type } = useParams();
    const location = useLocation();
    const heading = location?.state;

    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
            astroStatus
        }
        dispatch(astrologerList(data))
    };
    // interview

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, error: '', errors: '' });
    }


    const scheduleModalHideShow = (type, id) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, scheduleModal: true, id, error: '', })
        }
    }

    const holdApproveRejectModalHideShow = (type, id, endUrl) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, holdApproveRejectModal: true, id, error: '', endUrl })
        }
    }


    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!date) {
            error.dateError = " *Date is mandetory";
            formIsValid = false;
        }
        if (!time) {
            error.timeError = " *Time is mandetory";
            formIsValid = false;
        }
        if (!link) {
            error.linkError = " *Link is mandetory";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handleSchedule = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                astrologerId: id,
                date,
                time,
                link
            }
            dispatch(scheduleInterview(data))
                .then((result) => {
                    console.log({ result })

                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        updateState(initialState);
                        dispatch(astrologerList({ astroStatus: pathname }))
                    }

                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    const handleHoldRejectApproved = () => {

        if (!reason) {
            updateState({ ...iState, error: '*Reason is mandetory' })
        }
        else {
            const payload = {
                data: {
                    astrologerId: id,
                    reason
                },
                endUrl,
            }
            dispatch(holdrejectInterview(payload))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        updateState(initialState);
                        dispatch(astrologerList({ astroStatus: pathname }))
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }


    }



    const handleRefresh = () => {
        updateState(initialState);
        dispatch(astrologerList({ astroStatus: pathname }));
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '' && holdApproveRejectModal == false) {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '' && holdApproveRejectModal == false) {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '' && holdApproveRejectModal == false) {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                astroStatus: pathname,
                search,
                startDate: fromDate,
                endDate: toDate,
            }
            dispatch(astrologerList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        if (timeFrame) {
            const data = {
                astroStatus: pathname,
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
            }
            dispatch(astrologerList(data))
        }

    }, [timeFrame])


    useEffect(() => {
        updateState({ ...initialState, astroStatus: pathname })
        dispatch(astrologerList({ astroStatus: pathname }))
            .then((result) => {
                console.log({ result })
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {
                console.log({ err })
            });
    }, [pathname])

    console.log({ endUrl })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        {/* <h4 className="Title">New Registration</h4> */}
                    </div>
                    <Tab.Container defaultActiveKey={type}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="new registration" as={Link} to='/astrologer-management/new registration'
                                        state="Astrologer Management"
                                    >  New </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="interview" as={Link} to='/astrologer-management/interview'
                                        state="Astrologer Management"
                                    > Interview</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="on-hold" as={Link} to='/astrologer-management/on-hold'
                                        state="Astrologer Management"
                                    > On-Hold</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="rejected" as={Link} to='/astrologer-management/rejected' state="Astrologer Management"> Rejected</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content className="tab-content">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    className="TitleLink"
                                    href="javascript:void(0);"
                                    style={{ marginTop: "-85px" }}
                                >
                                    <i className="fa fa-download" /> Download Excel
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                                name='search'
                                                value={search}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control"
                                                min={fromDate}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button" onClick={handleFilterApply}>Apply</button>

                                            <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                                <option value='' selected={timeFrame == ""}>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <span className='error'>{error && !holdApproveRejectModal ? error : ''}</span>
                            </div>
                            <Tab.Pane className="tab-pane" eventKey='new registration'>
                                <div className="TableList">
                                    <table style={{ width: "135%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Astrologer ID</th>
                                                <th>
                                                    Display Name
                                                </th>
                                                <th>
                                                    Contact Number
                                                </th>
                                                <th>Email ID</th>
                                                <th>City</th>
                                                <th>Registered On</th>
                                                <th>
                                                    View Details
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAstrologerList?.data?.result?.length > 0 ?
                                                getAstrologerList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.astroNo}</td>
                                                            <td>{item?.fullName}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.city}</td>
                                                            <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                            <td>
                                                                <div className="Actions justify-content-start">
                                                                    <Link
                                                                        className="Blue ml-0"
                                                                        to="/new-astrologer-details"
                                                                        state={{ item, heading: "New Astrolger Details" }}
                                                                    >
                                                                        <i className="fa fa-eye" />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="NewActions justify-content-center">
                                                                    <a
                                                                        className="Blue"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => scheduleModalHideShow("show", item._id)}
                                                                    >
                                                                        Schedule Interview
                                                                    </a>
                                                                    <a
                                                                        className="Yellow"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "on-hold-astrologers")}
                                                                    >
                                                                        On-Hold
                                                                    </a>
                                                                    <a
                                                                        className="Red"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "reject-astrologers")}
                                                                    >
                                                                        Reject
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}

                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getAstrologerList?.data?.newRegisterTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getAstrologerList?.data?.newRegisterTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='interview'>
                                <div className="TableList">
                                    <table style={{ width: "135%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Astrologer ID</th>
                                                <th>
                                                    Display <br /> Name
                                                </th>
                                                <th>
                                                    Contact <br /> Number
                                                </th>
                                                <th>Email ID</th>
                                                <th>City</th>
                                                <th>Registered On</th>
                                                <th>View Details</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {getAstrologerList?.data?.result?.length > 0 ?
                                                getAstrologerList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.astroNo}</td>
                                                            <td>{item?.fullName}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.city}</td>
                                                            <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                            <td>
                                                                <div className="Actions justify-content-start">
                                                                    <Link
                                                                        className="Blue ml-0"
                                                                        to="/new-astrologer-details"
                                                                        state={{ item, heading: "New Astrolger Details" }}
                                                                    >
                                                                        <i className="fa fa-eye" />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="NewActions justify-content-center">
                                                                    <a
                                                                        className="Pink"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "approved-astrologers")}
                                                                    >
                                                                        Approve
                                                                    </a>
                                                                    <a
                                                                        className="Yellow"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "on-hold-astrologers")}
                                                                    >
                                                                        On-Hold
                                                                    </a>
                                                                    <a
                                                                        className="Red"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "reject-astrologers")}
                                                                    >
                                                                        Reject
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}

                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getAstrologerList?.data?.interviewTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getAstrologerList?.data?.interviewTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='on-hold'>
                                <div className="TableList">
                                    <table style={{ width: "135%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Astrologer ID</th>
                                                <th>
                                                    Display <br /> Name
                                                </th>
                                                <th>
                                                    Contact <br /> Number
                                                </th>
                                                <th>Email ID</th>
                                                <th>City</th>
                                                <th>Registered On</th>
                                                <th>
                                                    View <br /> Details
                                                </th>
                                                <th>
                                                    Interview <br /> Schedules
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAstrologerList?.data?.result?.length > 0 ?
                                                getAstrologerList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.astroNo}</td>
                                                            <td>{item?.fullName}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.city}</td>
                                                            <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                            <td>
                                                                <div className="Actions justify-content-start">
                                                                    <Link
                                                                        className="Blue ml-0"
                                                                        to="/new-astrologer-details"
                                                                        state={{ item, heading: "New Astrolger Details" }}
                                                                    >
                                                                        <i className="fa fa-eye" />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>{item?.interviewSchedule == true ? "Yes" : "No"}</td>
                                                            <td>
                                                                <div className="NewActions justify-content-center">
                                                                    <a
                                                                        className="Blue"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => scheduleModalHideShow("show", item._id)}
                                                                    >
                                                                        Schedule Interview
                                                                    </a>
                                                                    <a
                                                                        className="Red"
                                                                        href="javascript:void(0);"
                                                                        data-toggle="modal"
                                                                        onClick={() => holdApproveRejectModalHideShow("show", item._id, "reject-astrologers")}
                                                                    >
                                                                        Reject
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getAstrologerList?.data?.onHoldTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getAstrologerList?.data?.onHoldTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='rejected'>
                                <div className="TableList">
                                    <table style={{ width: "135%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>
                                                    Astrologer <br /> ID
                                                </th>
                                                <th>
                                                    Display <br /> Name
                                                </th>
                                                <th>
                                                    Contact <br /> Number
                                                </th>
                                                <th>
                                                    Email <br /> ID
                                                </th>
                                                <th>City</th>
                                                <th>
                                                    Registered <br /> On
                                                </th>
                                                <th>
                                                    View <br /> Details
                                                </th>
                                                <th>
                                                    Interview <br /> Schedules
                                                </th>
                                                <th>
                                                    Reason of <br /> Rejection
                                                </th>
                                            </tr>
                                        </thead>



                                        <tbody>
                                            {getAstrologerList?.data?.result?.length > 0 ?
                                                getAstrologerList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.astroNo}</td>
                                                            <td>{item?.fullName}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.city}</td>
                                                            <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                            <td>
                                                                <div className="Actions justify-content-start">
                                                                    <Link
                                                                        className="Blue ml-0"
                                                                        to="/new-astrologer-details"
                                                                        state={{ heading: "New Astrolger Details" }}
                                                                    >
                                                                        <i className="fa fa-eye" />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>--</td>
                                                            <td>---</td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getAstrologerList?.data?.rejectTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getAstrologerList?.data?.rejectTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </div>

            {/* schedule interview */}
            <Modal
                show={scheduleModal}
                onHide={() => scheduleModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            className="CloseModal"
                            onClick={() => scheduleModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>Schedule Interview</h3>
                        <div className="form-group">
                            <label>Date</label>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Enter Link"
                                name='date'
                                value={date}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.dateError}</span>
                        </div>
                        <div className="form-group">
                            <label>Time</label>
                            <input
                                type="time"
                                className="form-control"
                                placeholder="Enter Link"
                                name='time'
                                value={time}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.timeError}</span>
                        </div>
                        <div className="form-group">
                            <label>Enter Reason</label>
                            <textarea
                                rows={6}
                                className="form-control"
                                placeholder="Enter Reason"
                                defaultValue={""}
                                name='link'
                                value={link}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.linkError}</span>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" onClick={handleSchedule}>Add</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* hold */}
            <Modal
                show={holdApproveRejectModal}
                onHide={() => holdApproveRejectModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a

                            className="CloseModal"
                            onClick={() => holdApproveRejectModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>{endUrl == "reject-astrologers" ? "Add Reason of Rejection" : endUrl == "on-hold-astrologers" ? "On-Hold" : endUrl == "approved-astrologers" ? 'Approve Astrologer' : ""}</h3>
                        <div className="form-group">
                            <label>Enter Reason</label>
                            <textarea
                                rows={6}
                                className="form-control"
                                placeholder="Enter Reason"
                                defaultValue={""}
                                name='reason'
                                value={reason}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{error}</span>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" data-dismiss="modal"
                                onClick={handleHoldRejectApproved}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>




            <div className="ModalBox">
                <div id="UserDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this User ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="RejectionReason" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">

                            </div>
                        </div>
                    </div>
                </div>

                <div id="ProjectAdminEdit" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Edit Project Admin</h3>
                                    <div className="form-group">
                                        <label>Project Name</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <input type="text" className="form-control" />
                                        <span className="Verify">Verify </span>
                                    </div>
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Photo of User</label>
                                        <input type="file" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm password</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <button className="Button">Submit </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default NewRegister
